import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Banner from '../../components/shared/assets/OmodaAssets/o-universe.webp';
import Omoda from '../../components/shared/assets/OmodaAssets/omoda-logo-white.svg.svg';
import OmodaBannerTop from '../../components/shared/assets/OmodaAssets/omoda-universe.webp';
import OmodaBannerTopS from '../../components/shared/assets/OmodaAssets/universe.webp';
import Club from '../../components/shared/assets/OmodaAssets/club.webp';
import Life from '../../components/shared/assets/OmodaAssets/life.webp';
import Lab from '../../components/shared/assets/OmodaAssets/lab.webp';
import Tech from '../../components/shared/assets/OmodaAssets/tech.webp';

const useStyles = makeStyles(() => ({
  root: {},
  bannerSection: {
    minHeight: '100vh',
    backgroundImage: `url(${Banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'fixed',
    zIndex: '3',
    width: '100%',
    top: '0px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  scaledDown: {
    transform: 'scale(0)',
    animation: '$scaleEnter 1.5s',
    transition: ' transform ease-in-out 1s',
  },
  '@keyframes scaleEnter': {
    '0%': {
      transform: 'scale(1)', // Start the animation from scale 0
    },
    '100%': {
      transform: 'scale(3)',
      opacity: '0', // End the animation with scale 1 (original size)
    },
  },
  ImgHolder: {
    width: '20%',
    padding: '150px 0px',
    '@media (max-width: 768px)': {
      width: '50%',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '200px',
    },
  },
  banner: {
    height: 'calc(100vh - 60px)',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 20px',
    '@media (max-width: 600px)': {
      height: 'calc(50vh - 60px)',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      height: 'calc(50vh - 60px)',
    },
  },
  bannerLogo: {
    maxWidth: '500px',
  },
  textSection: {
    fontFamily: 'OmodaR',
    padding: '10vh 0 8rem',
    '@media (max-width: 600px)': {
      padding: '5vh 0 8rem',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      padding: '5vh 0 8rem',
    },
  },
  textSectionHeading: {
    fontFamily: 'OmodaR',
    padding: '4.5rem 20px',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '80px',
    lineHeight: '100px',
    textTransform: 'uppercase',
    fontWeight: 'normal',
    '@media (max-width: 600px)': {
      fontSize: '50px',
      lineHeight: '50px',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },
  textSectionHolder: {
    fontFamily: 'OmodaR',
    width: '40%',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '18px',
    '@media (max-width: 600px)': {
      width: '80%',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      width: '80%',
    },
  },
  bannerHeading: {
    fontFamily: 'OmodaR',
    color: 'white',
    fontWeight: 'normal',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '80px',
    lineHeight: '100px',
    '@media (max-width: 600px)': {
      fontSize: '50px',
      lineHeight: '50px',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },

  bannerHeadingSub: {
    fontFamily: 'OmodaR',
    color: 'white',
    margin: '0 auto',
    textAlign: 'center',
  },
  textSectionHeadingSpan: {
    fontFamily: 'OmodaR',
    textTransform: 'lowercase',
    fontStyle: 'italic',
  },
}));

const OUniverse = () => {
  const [scaled, setScaled] = useState(false);
  const classes = useStyles({ scaled });

  const handleEnter = () => {
    setScaled(true);
  };

  return (
    <div className={classes.root}>
      <div
        className={`${classes.bannerSection} ${scaled ? classes.scaledDown : ''}`}
        onClick={handleEnter}
      >
        <div className={classes.ImgHolder}>
          <img alt="" src={Omoda} width="100%" />
        </div>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${OmodaBannerTop})` }}>
        <img alt="" src={Omoda} width="100%" className={classes.bannerLogo} />
      </div>
      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          THE FUTURE
          <br />
          <span className={classes.textSectionHeadingSpan}>begins with</span> OMODA
        </h1>

        <p className={classes.textSectionHolder}>
          Welcome to O-UNIVERSE, a universe of exceptional design and innovative technology.
          O-UNIVERSE is a new dimension in which it is possible to get ahead of the usual time,
          using the power of design thought and latest generation technologies. This is a world
          where we get unique experiences and join forces to create something completely new.
        </p>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${OmodaBannerTopS})` }}>
        <h1 className={classes.bannerHeading}>O-UNIVERSE</h1>
        <p className={classes.bannerHeadingSub}>
          Witness a big technological explosion, the birth of a new universe - O‑UNIVERSE.
        </p>
      </div>

      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          COMMUNITY OF
          <br />
          <span className={classes.textSectionHeadingSpan}>like-minded </span> PEOPLE
        </h1>

        <p className={classes.textSectionHolder}>
          They are united by incredible energy, the desire to create, continuously improve and
          generate new ideas. Which of them will come true, we will find out very soon - after all,
          the future is much closer than it seems.
        </p>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${Club})` }}>
        <h1 className={classes.bannerHeading}>O-CLUB</h1>
        <p className={classes.bannerHeadingSub}>
          O-PEOPLE are experimenting and not afraid to take risks, creating the future right now.
        </p>
      </div>

      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          At the forefront
          <br />
          <span className={classes.textSectionHeadingSpan}> of</span> OMODA
        </h1>

        <p className={classes.textSectionHolder}>
          At the forefront of OMODA are our ambassadors, whose achievements and records are on the
          verge of human capabilities, beyond the reach.
        </p>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${Life})` }}>
        <h1 className={classes.bannerHeading}>O-LIFE</h1>
        <p className={classes.bannerHeadingSub}>
          In our new universe, the most valued ability is the ability to find the amazing in
          familiar things, to see new opportunities where others cannot.
        </p>
      </div>

      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          LOOK BEYOND
          <br />
          <span className={classes.textSectionHeadingSpan}> the</span> HORIZON
        </h1>

        <p className={classes.textSectionHolder}>
          {`O-LIFE is things and events that fill every day, allow you to see the world in 360
          degrees. O-PEOPLE try their hand at art, music, fashion, destroying internal barriers and
          expanding their horizons. An unlimited pass to an unforgettable experience that opens up
          to OMODA owners and friends of the brand. Join the O-PEOPLE lifestyle and become part of
          unique events and activities that the brand's team has prepared especially for you.`}
        </p>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${Lab})` }}>
        <h1 className={classes.bannerHeading}>O-LAB</h1>
        <p className={classes.bannerHeadingSub}>
          If you want to create a new universe with OMODA, stay tuned for O-LAB announcements and
          events!
        </p>
      </div>

      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          GO TO
          <br />
          <span className={classes.textSectionHeadingSpan}> the new</span> LEVEL
        </h1>

        <p className={classes.textSectionHolder}>
          Electric cars, drones and artificial intelligence will soon become our reality. O-LAB is a
          series of online lectures about the future of the automotive industry. The speakers are
          the best auto experts, journalists and bloggers. Together with our community, we will
          conduct the most daring innovative experiments, solve the mysteries of the universe and
          explore the fantastic world of the future.
        </p>
      </div>

      <div className={classes.banner} style={{ backgroundImage: `url(${Tech})` }}>
        <h1 className={classes.bannerHeading}>O-TECH</h1>
      </div>

      <div className={classes.textSection}>
        <h1 className={classes.textSectionHeading}>
          Technology is
          <br />
          <span className={classes.textSectionHeadingSpan}>Transforming</span>
        </h1>

        <p className={classes.textSectionHolder}>
          {`Technology is transforming the way we drive and own a car. Our understanding of the car
          and its role in modern life is on the verge of profound changes.In O-TECH, we'll show you
          the cutting-edge technology you already have under the hood for driving pleasure, and show
          you how to take your OMODA experience to the next level.`}
        </p>
      </div>
    </div>
  );
};

export default OUniverse;
