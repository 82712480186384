import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { MASTER_DOMAIN } from '../../components/shared/Constants';
import Logo from '../../components/shared/assets/jac/logo_jac.png';
import LogoMEKOR from '../../components/shared/assets/mekor3.png';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  barContainer: {
    width: '100%',
    position: 'sticky',
    right: '0',
    zIndex: '12',
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '6px solid #cc000d',
  },
  iconContainerOuter: {
    maxWidth: '325px',
    minWidth: '200px',
    width: '20%',
    aspectRatio: '0.65',
    zIndex: '10',
    background: 'white',
  },
  iconContainerInner: {
    display: 'flex',
    flex: 2,
    padding: '10px',
  },
  iconImg: {
    width: '100px',
  },
  sloganContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sloganDash: {
    width: '13%',
    minWidth: '5px',
    minHeight: '2px',
    background: 'black',
  },
  sloganTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sloganText: {
    fontSize: '0.8rem',
    fontWeight: '800',
    textTransform: 'uppercase',
    '@media (max-width: 1000px)': {
      fontSize: '0.5rem',
      fontWeight: '400',
    },
  },
  menusContainer: {
    width: '100%',
    flex: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      display: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: 'grey',
    paddingTop: 25,
    paddingBottom: 25,
    textAlign: 'center',
    fontSize: '16px',
    textTransform: 'uppercase',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
      cursor: 'pointer',
    },
    '@media (max-width: 1330px)': {
      fontSize: '11px',
    },
  },
  menuItemActive: {
    display: 'flex',
    flex: 1,
    maxPadding: 20,
    borderBottom: ({ websiteColors }) => `2px solid ${websiteColors.primaryColor}`,
    color: 'grey',
    fontSize: '16px',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
      cursor: 'pointer',
    },
  },
  burgerContainer: {
    position: 'relative',
    display: 'none',
    paddingRight: '10px',
    '@media (max-width: 1000px)': {
      display: 'block',
      position: 'relative',
      float: 'right',
    },
  },
  burger: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
      padding: '15px 0',
    },
  },
  pattie: {
    minWidth: '20px',
    maxWidth: '20px',
    minHeight: '2px',
    margin: '4px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
  },
  mobileMenusContainerOuter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    width: '100%',
    maxWidth: '300px',
    right: '0px',
    top: '0px',
    bottom: '0',
    position: 'fixed',
    transition: '0.5s all ease-in',
    zIndex: '50',
  },
  mobileMenusContainerInner: {
    background: 'white',
    width: '100%',
    maxWidth: '290px',
    right: '0px',
    top: '0px',
    bottom: '0',
    position: 'fixed',
  },
  mobileMenusContainerClose: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    textAlign: 'right',
    padding: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  mobileMenusContainer: {
    paddingTop: '27px',
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  mobileMenuItem: {
    padding: '19px 15px',
    color: 'grey',
    fontSize: '14px',
    textTransform: 'uppercase',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
      cursor: 'pointer',
    },
  },
  dropdownMenuLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropdownMenuItem: {
    textTransform: 'uppercase',
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
  },
  mekorLogoContainer: {
    flex: 1,
    marginLeft: 30,
    display: 'flex',
    paddingRight: 20,
    maxWidth: '30%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mekorLogoImg: {
    width: '100%',
    '@media (max-width: 1000px)': {
      width: '40%',
    },
  },
}));

const TopBarJAC = () => {
  const { websiteColors } = useContext(DealerContext);
  const [openMenu, setOpenMenu] = useState(100);
  const classes = useStyles({
    openMenu,
    websiteColors,
  });

  const goToMekor = () => {
    window.open(MASTER_DOMAIN, '_self');
  };

  const handleMobileMenu = () => {
    setOpenMenu(openMenu === 100 ? 0 : 100);
  };

  const topBarLinks = [
    { linkTo: '/show-room', linkName: 'Range' },
    // { linkTo: '/pre-owned', linkName: 'Pre-owned' },
    { linkTo: '/special-deals', linkName: 'Special Deals' },
    { linkTo: '/aftersales', linkName: 'Aftersales' },
    { linkTo: '/apply-for-finance', linkName: 'Finance' },
    { linkTo: '/about-us', linkName: 'About Us' },
    { linkTo: '/contact-us', linkName: 'Contact Us' },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.barContainer}>
        <div className={classes.iconContainerInner}>
          <Link to="/">
            <img alt="icon" className={classes.iconImg} src={Logo} width="100%" />
          </Link>
          {/* 
            <div className={classes.sloganContainer}>
              <div className={classes.sloganDash}></div>
                <div className={classes.sloganTextContainer}>
                  <Typography className={classes.sloganText}>Better drive</Typography>
                  <Typography className={classes.sloganText}>Better life</Typography>
                </div>
              <div className={classes.sloganDash}></div>
            </div> 
          */}
        </div>
        {/* Only visible for mobile devices */}
        <div className={classes.burgerContainer}>
          <div className={classes.burger} onClick={handleMobileMenu}>
            <div className={classes.pattie} />
            <div className={classes.pattie} />
            <div className={classes.pattie} />
          </div>
        </div>

        {/* Only visible for larger screens */}
        <div className={classes.menusContainer}>
          {topBarLinks.map((v, i) => (
            <NavLink
              // eslint-disable-next-line react/no-array-index-key
              key={`desktop_link_${i}`}
              className={classes.menuItem}
              activeClassName={classes.menuItemActive}
              to={v.linkTo}
            >
              {v.linkName}
            </NavLink>
          ))}
          <a
            className={classes.menuItem}
            href="https://www.selltomekor.co.za/"
            target="_blank"
            rel="noreferrer"
          >
            Sell My Car
          </a>

          <div className={classes.mekorLogoContainer} onClick={goToMekor}>
            <img alt="mekor_logo" className={classes.mekorLogoImg} src={LogoMEKOR} />
          </div>
        </div>
      </div>

      {/* Only visible for mobile devices */}
      <div className={classes.mobileMenusContainerOuter}>
        <div className={classes.mobileMenusContainerInner}>
          <div className={classes.mobileMenusContainerClose} onClick={handleMobileMenu}>
            <CloseIcon />
          </div>
          <div className={classes.mobileMenusContainer}>
            {topBarLinks.map((v, i) => (
              <Link
                // eslint-disable-next-line react/no-array-index-key
                key={`link_mobile${i}`}
                className={classes.mobileMenuItem}
                onClick={handleMobileMenu}
                to={v.linkTo}
              >
                <Typography>{v.linkName}</Typography>
              </Link>
            ))}
            <a
              className={classes.mobileMenuItem}
              href="https://www.selltomekor.co.za/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography>Sell My Car</Typography>
            </a>
            <div className={classes.mobileMenuItem} onClick={goToMekor}>
              <img alt="logo_mekor" className={classes.mekorLogoImg} src={LogoMEKOR} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBarJAC;
