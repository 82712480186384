import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

export default function DealsFilter(pageNumber, sortDirection) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [deals, setDeals] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { globalDealer } = useContext(DealerContext);
  let params = {};

  useEffect(() => {
    setDeals([]);
  }, [sortDirection]);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);

    params = {
      pageNumber,
      pageSize: PAGE_SIZE,
      status: 'active',
      dealerId: globalDealer.dealerId,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Offers`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
      .then((response) => {
        setHasMore(response.data?.list.length > 0);
        setLoading(false);
        setDeals(response.data?.list);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', e);
        setLoading(false);
      });
    return () => cancel();
  }, [pageNumber, sortDirection]);

  return { loading, error, deals, hasMore };
}
