import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { BASE_URI, DEALERID } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';
import BrandList from '../shared/BrandList/BrandList';
import ContactSection from '../ContactUs/ContactUs';
import AboutUs from '../AboutUs/AboutUs';
// import UsedVehicles from '../UsedVehicles/UsedVehicles';
import Carousel from '../shared/Carousel/SharedCarousel';

const useStyles = makeStyles(() => ({
  rootAbout: {
    margin: '20px 0px',
    background: '#eeeeee',
    position: 'relative',
    paddingRight: '70px',
    paddingTop: '50px',
    '@media (max-width: 614px)': {
      padding: '80px 40px ',
      paddingTop: '20px',
    },
  },
  rootHistory: {
    margin: '20px 0px',
    background: '#eeeeee',
    position: 'relative',
    paddingLeft: '70px',
    paddingTop: '50px',
    '@media (max-width: 614px)': {
      padding: '80px 40px ',
      paddingTop: '20px',
    },
  },
  rootHistoryInner: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootHistoryInnerImg: {
    minWidth: '50%',
    paddingRight: '70px',
    '@media (max-width: 614px)': {
      minWidth: 'unset',
    },
  },
  rootHistoryInnerTextHolder: {
    maxWidth: '50%',
    '@media (max-width: 614px)': {
      maxWidth: 'unset',
    },
  },
  root: {
    background: '#eeeeee',
  },
  img: {
    height: '100%',
    maxHeight: '160px',
    margin: '10px 0px',
    transition: '0.5s ease all',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  imgSectionHolder: {
    background: '#eeeeee',
    paddingTop: '2em',
    paddingBottom: '2em',
    position: 'relative',
  },
  textIntro: {
    fontSize: '16px',
  },
  section: {
    width: 'calc(100% / 2 - 40px)',
    margin: '20px',
    textAlign: 'center',
    '@media (max-width: 500px)': {
      width: 'unset',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: 'unset',
    },
  },
  listFlex: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 40px',
    width: '61%',
    margin: '0 auto',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      padding: '20px',
      width: 'unset',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      flexDirection: 'column',
      padding: '20px',
    },
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: 'left',
  },
  sellCarHolder: {
    padding: '20px',
    textAlign: 'center',
  },
  CarouselCarHolder: {
    margin: '20px auto',
    maxWidth: '1770px',
  },
  CarouselCarCta: {
    border: 'none',
    padding: '20px 40px',
    background: '#2c88d9',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '3px',
  },
  sectionHolder: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  ctaHolder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  textIntroHolder: {
    textAlign: 'center',
    padding: '20px',
  },
  testaHolder: {
    display: 'flex',
    maxWidth: '1770px',
    margin: '0 auto',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  testaCard: {
    width: 'calc(100% / 3 - 100px)',
    margin: '0px 50px',
    marginBottom: '50px',
    boxShadow: '0 0 5px 0 #001e4c70',
    background: 'white',
    height: '200px',
  },
  headingC: {
    textAlign: 'center',
    padding: '30px 0px',
  },
  textS: {
    fontSize: '16px',
  },
  MainImg: {
    position: 'relative',
    backgroundSize: 'cover',
    maxHeight: 'calc(90vh - 80px)',
    backgroundPosition: 'center',
    '@media (max-width: 613px)': {
      maxHeight: 'calc(50vh - 80px)',
    },
    '@media (min-width: 614px) and (max-width: 1000px)': {
      maxHeight: 'calc(50vh - 80px)',
    },
  },
  imgSectionHolderCar: {
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '40px',
    flexWrap: 'wrap',
  },
  imgSectionHolderCarBack: {
    backgroundImage: `url("https://picsum.photos/200/300")`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundColor: '#01172fa3',
    backgroundBlendMode: 'overlay',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      width: '50%',
    },
  },
  imgSectionAfterSales: {
    backgroundImage: `url("https://picsum.photos/200/300")`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundColor: '#000306a3',
    backgroundBlendMode: 'overlay',
    backgroundPosition: '95% 0%',
    display: 'flex',
    alignItems: 'self-end',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
      top: '0px',
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      bottom: '0px',
      width: '50%',
    },
    '@media (max-width: 600px)': {
      backgroundPosition: '95% 0%',
    },
  },
  imgSectionAfterSalesR: {
    backgroundImage: `url("https://picsum.photos/200/300")`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundColor: '#01172fa3',
    backgroundBlendMode: 'overlay',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'self-end',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
      top: '0px',
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      bottom: '0px',
      width: '50%',
    },
  },
  imgSectionHolderCarBlock: {
    maxWidth: 'calc(100% / 2 - 80px)',
    minWidth: 'calc(100% / 2 - 80px)',
    margin: '20px',
    lineHeight: '0px',
    display: 'block',
    '@media (max-width: 613px)': {
      minWidth: 'unset',
      maxWidth: 'unset',
    },
    '@media (min-width: 614px) and (max-width: 1023px)': {
      minWidth: 'calc(100% / 2 - 40px)',
      maxWidth: 'calc(100% / 2 - 40px)',
    },
  },
  HeadingBlue: {
    color: 'white',
    fontSize: '40px',
    textTransform: 'uppercase',
    width: '80%',
    margin: '0 auto',
    position: 'relative',
    zIndex: '2',
    paddingTop: '40px',
  },
  AfterSalesHeading: {
    color: 'white',
    fontSize: '150px',
    textTransform: 'uppercase',
    lineHeight: 1.1,
    '@media (max-width: 959px)': {
      fontSize: '50px',
    },
  },
  AfterSalesHeadingText: {
    color: 'white',
    fontSize: '40px',
    margin: 0,
    '@media (max-width: 600px)': {
      fontSize: '20px',
    },
  },
  AfterSalesButton: {
    background: 'white',
    fontSize: '30px',
    margin: 0,
    padding: '5px 40px',
    borderRadius: '50px',
    cursor: 'pointer',
    marginTop: '40px',
    '@media (max-width: 600px)': {
      fontSize: '18px',
      padding: '5px 20px',
    },
  },
  imgSectionHolderCarBlockText: {
    background: 'white',
    color: 'black',
    padding: '50px 0px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '40px',
  },
  HeadingHolder: {
    display: 'flex',
    justifyContent: 'end',
  },
  HeadingHolderAfterSales: {
    width: '80%',
    margin: '150px 70px',
    '@media (max-width: 600px)': {
      margin: '80px 40px',
    },
  },
  HeadingHolderAfterSalesR: {
    textAlign: 'end',
    margin: '150px 70px',
    '@media (max-width: 600px)': {
      margin: '80px 40px',
    },
  },
  HeadingBlueAbout: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    position: 'relative',
    zIndex: '2',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  HeadingBlueOverlayAbout: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    left: '50px',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    },
  },
  AboutTextHolder: {
    display: 'flex',
    justifyContent: 'right',
    position: 'relative',
  },
  AboutText: {
    width: '60%',
    textAlign: 'right',
    fontSize: '16px',
    '@media (max-width: 614px)': {
      width: 'unset',
    },
  },
  HistoryTextHolder: {
    justifyContent: 'right',
    position: 'relative',
  },
  HeadingBlueHistory: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    position: 'relative',
    zIndex: '2',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  HeadingBlueOverlayHistory: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    right: '50px',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    },
  },
  HeadingHolderHistory: {
    display: 'flex',
  },
  HistoryText: {
    width: '60%',
    textAlign: 'left',
    fontSize: '16px',
    '@media (max-width: 614px)': {
      width: 'unset',
    },
  },
  ContactSectionHolder: {
    backgroundColor: '#ffffff78',
    backgroundBlendMode: 'overlay',
    padding: '40px 70px',
    '@media (max-width: 959px)': {
      padding: '40px 10px',
    },
  },
  heading: {
    fontSize: '39px',
    textAlign: 'center',
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute',
  },
  vCardButtonNavMain: {
    padding: '10px 10px',
    borderRadius: '50px',
    margin: '10px 0px',
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer',
  },
}));

const HomeComponent = () => {
  let cancel;
  const { loading, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (loading) return;
    const params = {
      dealerId: DEALERID,
      pageNumber: 1,
      pageSize: 25,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
        buttonText: itm.buttonText,
      }));
      setSlides(tempSlides);
    });
    // eslint-disable-next-line consistent-return
    return () => cancel();
  }, [loading]);

  return (
    <div className={classes.root}>
      {slides?.length > 0 ? <Carousel slides={slides} /> : null}
      {/* <UsedVehicles /> */}
      <div className={classes.imgSectionHolder}>
        <BrandList />
      </div>
      <AboutUs />
      <div className={classes.ContactSectionHolder}>
        <ContactSection
          align="center"
          heading="Get in touch"
          subHeading="with us today"
          hidden
          home
        />
      </div>
    </div>
  );
};

export default HomeComponent;
