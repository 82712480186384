import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FinanceProvider } from './components/shared/contexts/FinanceContext';
import { DealerProvider } from './components/shared/contexts/DealerContext';
import './components/shared/assets/fonts/omoda-regular.ttf';
import './components/shared/assets/fonts/omoda-bold.ttf';
import './components/shared/assets/fonts/omoda-light.ttf';
import './components/shared/assets/fonts/omoda-ultralight.ttf';

ReactDOM.render(
  <React.StrictMode>
    <FinanceProvider>
      <DealerProvider>
        <App />
      </DealerProvider>
    </FinanceProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
