/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { DealerContext } from './DealerContext';
import {
  BASE_URI,
  MOTORGROUPID,
  MASTER_DEALERID,
  MASTER_CONFIGURATOR_DEALERID,
} from '../Constants';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, PUT',
  'Access-Control-Allow-Headers': 'Content-Type',
};

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const [vehiclesList, setVehiclesList] = useState();
  const [newVehiclesList, setNewVehiclesList] = useState([]);
  const [vehicleContextLoading, setVehicleContextLoading] = useState(false);
  const { globalDealer, dealerList } = useContext(DealerContext);

  const getNewVehicles = async (dealerID) => {
    const params = {
      dealerId: dealerID,
      pageNumber: 1,
      pageSize: 1001,
    };
    return axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
      headers,
    })
      .then((response) => {
        const arr = globalDealer?.relatedBrands.map((v) =>
          response.data?.list.filter((va) => va.makeId === v.id)
        );
        return arr.flat();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error while fetching dealer models.', error);
      });
  };

  const getAllNewVehicles = async () => {
    const dealerVehicles = await getNewVehicles(globalDealer?.dealerId);
    const masterVehicles = await getNewVehicles(MASTER_CONFIGURATOR_DEALERID);
    // eslint-disable-next-line array-callback-return
    const dVModelID = dealerVehicles?.map((dV) => {
      if (dV?.modelId) {
        return dV?.modelId;
      }
      return 0;
    });

    const masterVehiclesFiltered = masterVehicles?.filter((mV) => !dVModelID.includes(mV.modelId));

    if (masterVehiclesFiltered || dealerVehicles) {
      setNewVehiclesList([...masterVehiclesFiltered, ...dealerVehicles]);
    }
  };

  const getStock = async () => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
    };

    if (globalDealer?.dealerId != MASTER_DEALERID) {
      params.dealerId = globalDealer?.dealerId;
    } else {
      params.motorGroupId = MOTORGROUPID;
      // params.dealerId = 486;
    }

    await axios({
      method: 'GET',
      url: `${BASE_URI}/stock`,
      params,
      headers,
    })
      .then((response) => {
        const dealerIDs = dealerList?.map((d) => d?.dealerId);
        // Filter stock by dealerList
        const data = response?.data?.list?.filter((s) => dealerIDs?.includes(s?.dealerId));
        let aStockIDs = [];
        const fData = data?.reduce((ac, v) => {
          if (aStockIDs.includes(v?.foreignId)) {
            return ac;
          }
          aStockIDs = [...aStockIDs, v?.foreignId];
          return [...ac, v];
        }, []);
        setVehiclesList(fData);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error while fetching vehicle stock.', error);
      });
  };

  const getVehiclePromise = async () => {
    setNewVehiclesList([]);
    setVehiclesList([]);
    setVehicleContextLoading(true);
    await getAllNewVehicles();
    await getStock();
    setVehicleContextLoading(false);
  };

  useEffect(() => {
    if (globalDealer) {
      getVehiclePromise();
    }
  }, [globalDealer]);

  return (
    <VehicleContext.Provider
      value={{
        vehiclesList,
        setVehiclesList,
        setNewVehiclesList,
        getVehiclePromise,
        newVehiclesList,
        vehicleContextLoading,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};
