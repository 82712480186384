import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  imgHolder: {
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '500px',
    objectFit: 'cover',
  },
  Carousel: {
    // transform: (slidePosition) => `translate(${slidePosition}%, 0px)`,
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    transition: '0.5s all ease-in',
  },
  carouselBack: {
    left: '1%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:hover': {
      opacity: '1',
    },
  },
  carouselNext: {
    right: '1%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:hover': {
      opacity: '1',
    },
  },
  nextbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    right: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    transition: '0.1s all ease-in',
    '&:hover': {
      background: 'transparent',
      border: 'white 3px solid',
      color: 'white',
      opacity: '0.8',
      cursor: 'pointer',
    },
  },
  backbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    left: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    transition: '0.1s all ease-in',
    '&:hover': {
      background: 'transparent',
      border: 'white 3px solid',
      color: 'white',
      opacity: '0.8',
      cursor: 'pointer',
    },
  },
  btnHolder: {
    display: 'flex',
    position: 'absolute',
    width: '97%',
    justifyContent: 'space-between',
  },
}));

export default function Carousel(props) {
  const [currentSlide, setCurrentSlide] = useState(props?.slides[0] || null);
  const currentSlideIndexRef = useRef(0);
  const classes = useStyles(currentSlideIndexRef.current);

  const handleNextSlide = () => {
    let newIndex = 0;

    if (currentSlideIndexRef.current < props.slides.length - 1) {
      newIndex = currentSlideIndexRef.current + 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
  };

  useEffect(() => {
    if (props?.slides?.length > 0) {
      const interval = setInterval(() => {
        handleNextSlide();
      }, 500);
      clearInterval(interval);
    }
  }, [currentSlide]);

  const handlePreSlide = () => {
    let newIndex = props.slides.length - 1;

    if (currentSlideIndexRef.current > 0) {
      newIndex = currentSlideIndexRef.current - 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.Carousel}>
        <a href={currentSlide?.targetUrl}>
          {currentSlide?.content}
          <img
            alt=""
            className={classes.imgHolder}
            width="100%"
            src={currentSlide?.img ?? currentSlide}
          />
        </a>
      </div>

      <div className={classes.btnHolder}>
        <button type="button" className={classes.carouselBack} onClick={handlePreSlide}>
          <ChevronLeftIcon style={{ fontSize: 40 }} />
        </button>
        <button type="button" className={classes.carouselNext} onClick={handleNextSlide}>
          <ChevronRightIcon style={{ fontSize: 40 }} />
        </button>
      </div>
    </div>
  );
}
