import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 6),
  },
  link: {
    color: 'blue',
    textDecoration: 'none',
  },
  text: {
    padding: 15,
  },
}));

const Cookies = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography
          className={classes.text}
          gutterBottom
          variant="h4"
          color="textPrimary"
          fontWeight="500"
        >
          Our use of cookies
        </Typography>
        <p className={classes.text}>
          This website - Mekor Motors - uses cookies to allow us to see how our site is used. The
          cookies cannot identify you. If you continue to use this site we will assume that you are
          happy with this.
        </p>
        <p className={classes.text}>
          Cookies are small text files that are placed on your machine to provide a better user
          experience. In general, cookies are used to retain your preferences and provide anonymised
          tracking data to third party applications like Google Analytics.
        </p>
        <p className={classes.text}>
          However, you may prefer to disable cookies on this site and on others. The most effective
          way to do this is to disable cookies in your browser. We suggest consulting the Help
          section of your browser or taking a look at Wikipedia which offers guidance for all modern
          browsers
        </p>
        <p className={classes.text}>
          You may also want to read our{' '}
          <Link component={RouterLink} to="/terms" className={classes.link}>
            Terms & Conditions
          </Link>
        </p>
        <br />
        <Typography
          className={classes.text}
          gutterBottom
          variant="h5"
          color="textPrimary"
          fontWeight="500"
        >
          Contacting Us
        </Typography>
        <p className={classes.text}>
          If there are any questions regarding our Cookie Policy,{' '}
          <Link component={RouterLink} to="/contact-us" className={classes.link}>
            contact us
          </Link>
          .
        </p>
      </div>
      <div>
        <Typography
          className={classes.text}
          gutterBottom
          variant="h5"
          color="textPrimary"
          fontWeight="500"
        >
          Additional Links
        </Typography>
        <ul>
          <li>
            <Link component={RouterLink} to="/popi" className={classes.link}>
              Personal information request form
            </Link>
          </li>
          <li>
            <Link component={RouterLink} to="/terms" className={classes.link}>
              Terms & Conditions
            </Link>
          </li>
        </ul>
      </div>
      <br />
    </Container>
  );
};

export default Cookies;
