import React, { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 15px',
    '@media (max-width: 600px)': {
      padding: '0px 0px',
    },
  },
  innerSection: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 15px',
    background: ({ props }) => `${props.home ? 'white' : 'unset'}`,
    borderRadius: '10px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  innerSectionMap: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '6px 15px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  innerSectionMapIframe: {
    minWidth: 'calc(100% / 3 - 40px)',
    maxWidth: 'calc(100% / 3 - 40px)',
    margin: '20px',
    background: 'white',
    borderRadius: '4px',
    padding: '15px',
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  black: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white!important',
  },
  yellow: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontWeight: 'bold',
    color: 'white!important',
  },
  btn: {
    backgroundColor: '#f0f8ff00',
    border: 'none',
    padding: '10px ',
    cursor: 'pointer',
    color: 'white!important',
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    height: '3px',
    margin: '10px auto',
  },
  dealerName: {
    fontSize: '18px',
    margin: '5px 0px',
    fontWeight: 'bold!important',
  },
  flexHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
}));

const Accessories = (props) => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);

  const classes = useStyles({ websiteColors, props });

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            {!props.heading ? 'NEED ' : props.heading}
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            {!props.subHeading ? 'Accessories ?' : props.subHeading}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Leave a message, we will get back to you.
          </Typography>
        </div>
        <div className={classes.section}>
          <Form
            dealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            leadTypeId={3}
            masterDealerId={MASTER_DEALERID}
            activeDealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name?.replace('Master', ' ')}
            dealers={dealerList}
            dealerType="multi_dealer"
            note
          />
        </div>
      </div>
    </div>
  );
};

export default Accessories;
