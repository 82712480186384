import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import HomeComponent from './HomeComponent/HomeComponent';
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import Showroom from '../components/Showroom/Showroom';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import VehicleFinance from '../components/Finance/VehicleFinance';
import ContactUs from '../components/ContactUs/ContactUs';
import BookService from '../components/BookService/BookService';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from '../components/DetailedSpecialDeals/DetailedSpecialDeals';
import Parts from '../components/Parts/Parts';
import UsedVehicles from '../components/UsedVehicles/UsedVehicles';
import NotFound from './NotFound/NotFound';
import OUniverse from './OUniverse/OUniverse';
import DetailedUsedVehicle from '../components/DetailedUsedVehicle/DetailedUsedVehicle';
import Dealers from './Dealers/Dealers';
import BookATestDrive from './BookATestDrive/BookATestDrive';
import Popi from '../components/Legal/Popia';
import Cookies from '../components/Legal/Cookies';
import Terms from '../components/Legal/Terms';
import { DealerContext } from '../components/shared/contexts/DealerContext';
import ComingSoonVehicle from './DetailedVehicleGT/ComingSoonVehicle';
import DetailedVehicleC9 from './DetailedVehicle/DetailedVehicleC9';

export default function DashboardContainerOmoda() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();
  return (
    <main>
      <TopBar />
      <Switch>
        <Route exact path="/" render={() => <HomeComponent />} />
        <Route exact path="/dealer/:name" render={() => <HomeComponent />} />
        <Route exact path="/showroom" render={() => <Showroom />} />
        <Route path="/showroom-gt/:model/:stockId/" component={ComingSoonVehicle} />
        <Route exact path="/showroom/:model/:stockId" render={() => <DetailedVehicle />} />
        <Route exact path="/showroom-c9/:model/:stockId" render={() => <DetailedVehicleC9 />} />
        <Route exact path="/used-vehicles" render={() => <UsedVehicles />} />
        <Route exact path="/book-a-testdrive" render={() => <BookATestDrive />} />
        <Route exact path="/book-a-testdrive/:stockId" render={() => <BookATestDrive />} />
        <Route
          exact
          path="/book-a-testdrive/:stockId/:variantId"
          render={() => <BookATestDrive />}
        />
        <Route exact path="/used-vehicles/:make/:stockId" render={() => <DetailedUsedVehicle />} />
        <Route exact path="/book-a-service" render={() => <BookService />} />
        <Route exact path="/find-a-dealer" render={() => <Dealers />} />
        <Route exact path="/parts" render={() => <Parts />} />
        <Route exact path="/promotions" render={() => <SpecialDeals />} />
        <Route exact path="/special-deals/:dealId" render={() => <DetailedSpecialDeals />} />
        <Route exact path="/apply-for-finance" component={VehicleFinance} />
        <Route exact path="/apply-for-finance/:variant/:variantId/:id" component={VehicleFinance} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/brand/o-universe" component={OUniverse} />
        <Route exact path="/legal-information" render={() => <Cookies />} />
        <Route exact path="/terms-conditions" render={() => <Terms />} />
        <Route exact path="/privacy-policy" render={() => <Popi />} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} />
    </main>
  );
}
