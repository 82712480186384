import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { SellYourVehicle } from '@red-build/leadcentre';
import { BASE_URI, WEPAY4CARS_URI } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '70px 15px',
    '@media (max-width: 800px)': {
      padding: '50px 15px',
    },
  },
  innerSection: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 15px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      width: '40%',
      marginRight: '40px',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  titleTop: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '2rem',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      fontSize: '2.7rem',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      fontSize: '3.2rem',
    },
  },
  titleTopAccent: {
    fontSize: '3.7rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '2.2rem',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      fontSize: '3rem',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      fontSize: '3.4rem',
    },
  },
  mapHolder: {
    padding: '20px 0px',
    aspectRatio: '1',
  },
  dealerDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 5px',
  },
}));

const SellMyCar = () => {
  const classes = useStyles();
  const { globalDealer, websiteColors, dealers } = useContext(DealerContext);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            SELL MY
          </Typography>

          <Typography variant="h1" className={classes.titleTopAccent}>
            CAR
          </Typography>
        </div>

        <div className={classes.section}>
          <SellYourVehicle
            base_uri={BASE_URI}
            wePay4Cars_uri={WEPAY4CARS_URI}
            dealerId={globalDealer?.dealerId}
            active
            leadSourceId={1}
            websiteColors={websiteColors}
            dealerList={dealers}
          />
        </div>
      </div>
    </div>
  );
};

export default SellMyCar;
