/* eslint-disable no-plusplus */
import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URI } from '../shared/Constants';
import { VehicleContext } from '../shared/contexts/VehicleContext';
import WAFAB from './WAFAB';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    transition: 'all 0.5s ease!important',
    backgroundPosition: '100% 80%',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '20px',
    backgroundColor: '#00000085',
    backgroundBlendMode: 'overlay',
    zIndex: 100,
    top: 0,
    '@media (min-width: 601px) and (max-width: 1333px)': {
      height: '100%',
    },
    '@media (max-width: 601px)': {
      height: '110%',
      flexShrink: 0.5,
      backgroundPosition: '100% 100%',
    },
    '@media (max-width: 400px)': {
      height: '130%',
      flexShrink: 0.5,
      backgroundPosition: '100% 100%',
    },
  },
  rootOuter: {
    width: '100vw',
    height: '100vh',
    display: 'block',
    position: 'fixed',
    background: 'white',
    backgroundColor: 'white',
    zIndex: 200,
    top: 0,
    '@media (max-width: 959px)': {
      display: 'flex',
      position: 'absolute',
      height: '100%',
    },
  },
  rootInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    width: '100%',
    maxWidth: '570px',
    marginLeft: '50px',
    background: '#0c0c0c',
    borderRadius: '1rem!important',
    boxShadow: '0 0 15px #0000001c',
    '@media (max-width: 959px)': {
      margin: '0px',
      height: 'unset',
    },
  },
  modelHolder: {
    display: 'flex',
    flexDirection: 'row',
    padding: '18px 0px',
    transform: (slidePosition) =>
      !slidePosition === 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`,
    '@media (max-width: 600px)': {
      transform: (slidePosition) =>
        !slidePosition <= 0 ? `translateX(${slidePosition * 4}%)` : `translateX(0%)`,
    },
    '@media (min-width: 601px) and (max-width: 1333px)': {
      transform: (slidePosition) =>
        !slidePosition <= 0 ? `translateX(${slidePosition * 2}%)` : `translateX(0%)`,
    },
  },
  model: {
    marginRight: '8px',
    cursor: 'pointer',
    color: 'white',
    textTransform: 'uppercase',
    margin: '0px 10px',
    minWidth: 'calc(100% / 5 - 20px)',
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      minWidth: 'calc(100% / 4 - 20px)',
    },
    '@media (min-width: 601px) and (max-width: 1333px)': {
      minWidth: 'calc(100% / 5 - 20px)',
    },
  },
  modelActive: {
    marginRight: '8px',
    textTransform: 'uppercase',
    color: '#c7001f',
    margin: '0px 10px',
    minWidth: 'calc(100% / 5 - 20px)',
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      minWidth: 'calc(100% / 4 - 20px)',
    },
    '@media (min-width: 601px) and (max-width: 1333px)': {
      minWidth: 'calc(100% / 5 - 20px)',
    },
  },
  title: {
    fontSize: '38px',
    fontWeight: 'bold',
    color: 'white',
    padding: '10px 20px 30px 20px',
    '@media (max-width: 959px)': {
      padding: '0px',
    },
  },
  rootInnerTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: 'white',
  },
  rootInnerTitleAccent: {
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#c7001f',
    marginBottom: '16px',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: 'calc(100% / 1)',
  },
  inputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputS: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: 'calc(100% / 2 - 10px)',
  },
  textHolder: {
    padding: '15px',
    borderRadius: '0rem 0rem 10px 10px!important',
  },
  formHolder: {
    background: 'white',
    padding: '15px',
    borderRadius: '0rem 0rem 10px 10px!important',
  },
  Link: {
    border: 'none',
    borderRadius: '50px',
    padding: '10px 40px',
    background: '#b81b22',
    color: 'white',
    cursor: 'pointer',
    margin: '15px 0 0',
    '&:hover': {
      background: '  #9b161c',
      boxShadow: '0 0 5px 0  #9b161c',
    },
  },
  cardHolderOutter: {
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    margin: '0 auto',
  },
  cardBack: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    left: 10,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardNext: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    right: 10,
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  icon: {
    fontSize: '50px',
    color: '#c7001f',
  },
  flexRow: {
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
      height: '80%',
    },
  },
  vehicleCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    width: '100%',
    margin: '0px 20px',
    borderRadius: '1rem',
    '@media (max-width: 959px)': {
      margin: '20px 0px 0px 0px',
    },
  },
  wafabBottom: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    '@media (max-width: 959px)': {
      textAlign: 'left',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));

const BookATestDrive = () => {
  const history = useHistory();
  const paramss = useParams();
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer } = useContext(DealerContext);
  const [activeModel, setActiveModel] = useState();
  const [vehiclesList, setVehiclesList] = useState([]);
  const [vehicleVariant, setVehicleVariant] = useState([]);
  const [vehicleVariantActive, setVehicleVariantActive] = useState([]);
  const [vehicleVariantActiveData, setVehicleVariantActiveData] = useState({});
  const slidePosition = 0;
  const classes = useStyles(slidePosition);

  const handleModelChange = (v) => {
    history.push(`/book-a-testdrive/${v.ownedModelId}`);
    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];
      element.active = false;
    }
    // eslint-disable-next-line no-param-reassign
    v.active = true;
    setActiveModel(v);
    const vehiclesListRed = vehiclesList?.reduce((ac, veh) => {
      if (veh?.id === 659 || veh?.id === 905) {
        return ac;
      }
      return [...ac, veh];
    }, []);
    setVehiclesList(vehiclesListRed);
  };

  useEffect(() => {
    if (newVehiclesList?.length > 0) {
      handleModelChange(newVehiclesList[0]);
      history.push(`/book-a-testdrive/${newVehiclesList[0]?.ownedModelId}`);
      const vehiclesListRed = newVehiclesList?.reduce((ac, veh) => {
        if (veh?.id === 659 || veh?.id === 905) {
          return ac;
        }
        return [...ac, veh];
      }, []);
      setVehiclesList(vehiclesListRed);
    }
  }, [newVehiclesList]);

  useEffect(() => {
    const params = {
      makeId: 8104,
      dealerModelId: paramss?.stockId,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setVehicleVariant(response.data.list);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [paramss]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setVehicleVariantActive(response.data.contentImages);
        setVehicleVariantActiveData(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [paramss]);

  useEffect(() => {
    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];
      if (paramss?.stockId === element?.ownedModelId) {
        element.active = true;
        setActiveModel(element);
      }
    }
  }, [vehiclesList, vehicleVariantActive, vehicleVariant]);

  return (
    <div className={classes.rootOuter}>
      <div
        className={classes.root}
        style={{ backgroundImage: `url(${vehicleVariantActive[0]?.image?.imageUrl})` }}
      >
        <div className={classes.wafabBottom}>
          <Typography className={classes.title}>BOOK A TEST DRIVE</Typography>
          <WAFAB vehicle={activeModel?.modelName} />
        </div>
        <div className={classes.flexRow}>
          <div className={classes.rootInner}>
            <div className={classes.textHolder}>
              <Typography className={classes.rootInnerTitle}>SELECT A MODEL</Typography>
              <Typography className={classes.rootInnerTitleAccent}>
                {activeModel?.make} {activeModel?.model}
              </Typography>
              <div className={classes.cardHolderOutter}>
                <div className={classes.modelHolder}>
                  {vehiclesList
                    ?.filter((v) => v?.title.replace(' ', '').length > 0)
                    .map((vehicle) => (
                      <div
                        className={vehicle.active === true ? classes.modelActive : classes.model}
                        onClick={() => {
                          handleModelChange(vehicle);
                        }}
                      >
                        {vehicle?.model.replace('Mitsubishi', '').replace('MITSUBISHI', '')}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={classes.formHolder}>
              <Formik
                initialValues={{ email: '', password: '' }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  const { dealerId } = globalDealer;
                  const dealerName = globalDealer.name;
                  const variantId = values.variant;
                  const firstName = values.name;
                  const emailAddress = values.email;
                  const phoneNumber = values.phone;
                  const aData = {
                    dealerId,
                    dealerName,
                    variantId,
                    firstName,
                    emailAddress,
                    phoneNumber,
                    leadTypeId: 8,
                    leadSourceId: 1,
                  };

                  fetch('https://conexa.r-e-d.co.za/api/leads', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Methods': 'POST, GET, PUT',
                      'Access-Control-Allow-Headers': 'Content-Type',
                    },
                    body: JSON.stringify(aData),
                  })
                    .then()
                    .catch(() => {
                      // eslint-disable-next-line no-console
                      console.warn('Failed');
                    })
                    .then(() => {
                      setSubmitting(false);
                      history.push('/');
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.inputGroup}>
                      <div className={classes.input}>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && errors.name}
                      </div>
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputS}>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && errors.email}
                      </div>
                      <div className={classes.inputS}>
                        <TextField
                          id="phone"
                          label="Phone"
                          required
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone && errors.phone}
                      </div>
                    </div>
                    <div>
                      <TextField
                        id="variant"
                        label="Select a Variant"
                        variant="outlined"
                        fullWidth
                        required
                        select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.variant}
                      >
                        {vehicleVariant?.map((d) => (
                          <MenuItem value={d?.title}>{d?.title}</MenuItem>
                        ))}
                      </TextField>
                      {errors.variant && touched.variant && errors.variant}
                    </div>
                    <button className={classes.Link} type="submit" disabled={isSubmitting}>
                      SUBMIT
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className={classes.vehicleCard}>
            <img
              alt="Vehicle"
              style={{
                backgroundColor: 'white',
                display: 'flex',
                width: 'auto',
                maxHeight: '40%',
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
                objectFit: 'contain',
              }}
              src={activeModel?.image}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'black',
                height: '60%',
                padding: '16px',
                borderBottomLeftRadius: '1rem',
                borderBottomRightRadius: '1rem',
              }}
            >
              <Typography
                style={{
                  color: 'white',
                  fontSize: '20px',
                  textAlign: 'center',
                  paddingBottom: '5px',
                  marginBottom: '5px',
                  borderBottom: '1px solid white',
                  fontWeight: '800',
                }}
              >
                {vehicleVariantActiveData?.introductionTitle}
              </Typography>
              <Typography style={{ color: 'white', fontSize: '16px', textAlign: 'center' }}>
                {vehicleVariantActiveData?.introductionDesc}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookATestDrive;
