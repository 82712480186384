import React, { useContext } from 'react';
import { Route, useLocation, Switch, Redirect } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import HomeComponent from './HomeComponent/HomeComponent';
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import SellVehicle from './SellVehicle/SellVehicle';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import AboutUs from './AboutUs/AboutUs';
import UsedVehicles from './UsedVehicles/UsedVehicles';
import Popi from './Legal/Popia';
import Cookies from './Legal/Cookies';
import Terms from './Legal/Terms';
import AfterSales from './AfterSales/AfterSales';
import Service from './BookService/BookService';
import Parts from './Parts/Parts';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import EventDetailed from './events/EventDetailed';
import Accessories from './Accessories/Accessories';
import Dealers from './Dealers/Dealers';
import BrandList from './shared/BrandList/BrandList';
import DealerList from './shared/BrandList/DealerList';
import { DealerContext } from './shared/contexts/DealerContext';
import NotFound from '../OmodaComponents/NotFound/NotFound';
import SpecialBrandsList from './SpecialDeals/SpecialBrandsList';
import SpecialDealersList from './SpecialDeals/SpecialDealersList';

export default function DashboardContainer() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();
  return (
    <main>
      <TopBar />
      <div style={{ minHeight: '67vh', overflowY: 'scroll' }}>
        <Switch>
          {/* Redirects */}
          <Route path="/specials">
            <Redirect to="/special-deals" />
          </Route>
          <Route path="/brand/*">
            <Redirect to="/our-brands" />
          </Route>
          <Route path="/parts-inquiry">
            <Redirect to="/parts" />
          </Route>
          <Route path="/finance-inquiry">
            <Redirect to="/apply-for-finance" />
          </Route>
          <Route path="/used-cars/*">
            <Redirect to="/used-vehicles" />
          </Route>
          <Route path="/book-a-service">
            <Redirect to="/service" />
          </Route>
          <Route path="/vehicle-search/*">
            <Redirect to="/our-brands" />
          </Route>
          <Route path="/vehicle/*">
            <Redirect to="/our-brands" />
          </Route>
          <Route path="/careers/*">
            <Redirect to="/about" />
          </Route>
          <Route path="/content/*">
            <Redirect to="/special-deals" />
          </Route>
          {/* Internal Routes */}
          <Route exact path="/" render={() => <HomeComponent />} />
          <Route exact path="/after-sales" render={() => <AfterSales />} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/parts" component={Parts} />
          <Route exact path="/used-vehicles" render={() => <UsedVehicles />} />
          <Route exact path="/our-brands" render={() => <BrandList />} />
          <Route exact path="/our-brands/:brand" render={() => <DealerList />} />
          <Route exact path="/about" render={() => <AboutUs />} />
          <Route path="/show-room/:model/:stockId" render={() => <DetailedVehicle />} />
          <Route path="/pre-owned/:model/:stockId" render={() => <DetailedUsedVehicle />} />
          <Route exact path="/sell-your-vehicle" render={() => <SellVehicle />} />
          <Route exact path="/special-deals" render={() => <SpecialBrandsList />} />
          <Route exact path="/special-deals/:brand" render={() => <SpecialDealersList />} />
          <Route exact path="/apply-for-finance" component={VehicleFinance} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route path="/popi" component={Popi} />
          <Route path="/cookies" component={Cookies} />
          <Route path="/terms" component={Terms} />
          <Route path="/event/:event_id" component={EventDetailed} />
          <Route path="/accessories" component={Accessories} />
          <Route exact path="/find-a-dealer" render={() => <Dealers />} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} />
    </main>
  );
}
