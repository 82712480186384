import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '100px 20px',
    position: 'fixed',
    background: 'white',
    top: '0',
  },
  rootInner: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    fontFamily: 'OmodaR',
    fontSize: '24px',
    marginRight: '20px',
    borderRight: '1px solid rgba(0, 0, 0, .3)',
    padding: '0 23px 0 0',
  },
  msg: {
    fontFamily: 'OmodaR',
    fontSize: '14px',
  },
}));

const NotFound = () => {
  const classes = useStyles();
  // const history = useHistory();

  // useEffect(() => {
  //   setTimeout(() => {
  //     history.push('/');
  //   }, 2500);
  // }, []);

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <h1 className={classes.status}>404</h1>
        <p className={classes.msg}>THIS PAGE COULD NOT BE FOUND.</p>
      </div>
    </div>
  );
};

export default NotFound;
