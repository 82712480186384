/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';
import { Formik, Field, ErrorMessage } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { GAEvent } from '@red-build/leadcentre';
import { BASE_URI } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '50px',
    flexDirection: 'column',
  },
  rootInner: {
    width: '100%',
    maxWidth: '1090px',
    padding: '50px',
    '@media (max-width: 959px)': {
      margin: '0px',
    },
  },
  rootInnerTitle: {
    fontFamily: 'OmodaL',
    fontSize: '52px',
    color: 'black',
  },
  input: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputS: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: 'calc(100% / 2 - 10px)',
  },
  textHolder: {
    padding: '15px',
  },
  Link: {
    border: 'none',
    padding: '10px 40px',
    backgroundColor: ({ websiteColors }) => `${websiteColors?.accentColor}`,
    color: 'white',
    cursor: 'pointer',
    margin: '15px 0 0',
    width: '500px',
    fontFamily: 'OmodaR',
  },
  cardHolderOutter: {
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    margin: '0 auto',
  },
  cardBack: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    left: 10,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardNext: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 50,
    right: 10,
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  icon: {
    fontSize: '50px',
    color: '#c7001f',
  },
  btnSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const BookATestDrive = () => {
  const history = useHistory();
  const paramss = useParams();
  const { newVehiclesList } = useContext(VehicleContext);
  const { websiteColors, globalDealer } = useContext(DealerContext);
  const [activeModel, setActiveModel] = useState();
  const [vehicleVariant, setVehicleVariant] = useState([]);
  const [vehicleVariantActive, setVehicleVariantActive] = useState([]);
  const classes = useStyles({ websiteColors });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = {
      makeId: 8104,
      dealerModelId: paramss?.stockId,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setVehicleVariant(response.data.list);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [paramss]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setVehicleVariantActive(response.data.contentImages);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [paramss]);

  useEffect(() => {
    if (newVehiclesList) {
      // eslint-disable-next-line array-callback-return
      newVehiclesList.map((v) => {
        // eslint-disable-next-line eqeqeq
        if (paramss?.stockId == v?.ownedModelId) {
          setActiveModel(v);
        }
      });
    }
  }, [newVehiclesList]);

  const createVehicleLead = (values) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leadVehicles`,
      data: values,
      cancelToken: source.token,
    }).catch((error) => {
      if (axios.isCancel(error)) return;
      // eslint-disable-next-line no-console
      console.warn('Unable to complete the request', error);
    });
  };

  const createLead = (values, setSubmitting) => {
    const pageTitle = document.getElementsByTagName('title')[0].innerHTML;
    GAEvent('form_submissions', 'form_submissions', 'testDrive_submission', 'xhr', {
      url: window.location.pathname,
      page_title: pageTitle,
      form_type: 'testDrive',
    });
    const params = {};
    params.dealerId = globalDealer?.dealerId;
    params.dealerName = globalDealer?.name;
    params.leadTypeId = 8;
    params.name = values.firstName;
    params.firstName = values.firstName;
    params.lastName = values.lastName;
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = 1;
    params.dateUpdated = new Date().toISOString();
    params.variantId = paramss?.variantId || vehicleVariant[0]?.variantId;

    const { CancelToken } = axios;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leads`,
      data: params,
      cancelToken: source.token,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT',
        'Access-Control-Allow-Headers': 'Content-Type',
      },
    })
      .then((response) => {
        if (paramss?.variantId) {
          const resvalues = {};
          resvalues.leadId = response.data.id;
          resvalues.variantId = paramss?.variantId || vehicleVariant[0]?.variantId;
          createVehicleLead(resvalues);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        // eslint-disable-next-line no-console
        console.warn('Unable to complete the request', error);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.rootInnerTitle}>
        {activeModel?.isComingSoon ? 'BE THE FIRST TO KNOW' : 'BOOK A TEST DRIVE'}
      </Typography>
      <div className={classes.rootInner}>
        <Formik
          initialValues={{ email: '', privacy: [] }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            if (!values.firstName) {
              errors.firstName = 'Please enter a first name';
            }
            if (!values.lastName) {
              errors.lastName = 'Please enter a last name';
            }
            if (!values.privacy || values.privacy.length <= 0) {
              errors.privacy = 'Please accept the Privacy Policy.';
            }
            if (values.phone && !/^0(6|7|8){1}[0-9]{8}$/i.test(values.phone.toString())) {
              errors.phone = 'Invalid mobile number';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              createLead(values, setSubmitting);
            }, 400);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputGroup}>
                <div className={classes.inputS}>
                  <label
                    style={{
                      paddingBottom: '10px',
                      paddingTop: '25px',
                      fontSize: '15px',
                      fontFamily: 'OmodaL',
                      color: websiteColors.accentColor,
                    }}
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="firstName" component="div" />
                </div>
                <div className={classes.inputS}>
                  <label
                    style={{
                      paddingBottom: '10px',
                      paddingTop: '25px',
                      fontSize: '15px',
                      fontFamily: 'OmodaL',
                      color: websiteColors.accentColor,
                    }}
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <TextField
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="lastName" component="div" />
                </div>
              </div>
              <div className={classes.inputGroup}>
                <div className={classes.inputS}>
                  <label
                    style={{
                      paddingBottom: '10px',
                      paddingTop: '25px',
                      fontSize: '15px',
                      fontFamily: 'OmodaL',
                      color: websiteColors.accentColor,
                    }}
                    htmlFor="phone"
                  >
                    Phone Number
                  </label>
                  <TextField
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="phone" component="div" />
                </div>
                <div className={classes.inputS}>
                  <label
                    style={{
                      paddingBottom: '10px',
                      paddingTop: '25px',
                      fontSize: '15px',
                      fontFamily: 'OmodaL',
                      color: websiteColors.accentColor,
                    }}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="email" component="div" />
                </div>
              </div>
              <label>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Field
                    style={{ marginRight: 20 }}
                    required
                    type="checkbox"
                    name="privacy"
                    value="true"
                  />
                  <p style={{ color: websiteColors?.secondaryColor }}>
                    I hereby consent to Mekor Omoda to contact me regarding my enquiry
                  </p>
                </div>
                <ErrorMessage style={{ color: 'red' }} name="privacy" component="div" />
              </label>
              <div className={classes.btnSection}>
                <button className={classes.Link} type="submit" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BookATestDrive;
