import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
// import Staff from '../../components/Staff/Staff';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import JAC_LOGO from '../../components/shared/assets/jac/logo_jac.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0px',
    backgroundColor: 'white',
  },
  sectionBackground: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    padding: '50px 0px 100px 0px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionTitleContainerMiddle: {
    padding: (props) => (props.addTopMargin === true ? '0 0 200px 0' : '100px 0'),
    display: 'flex',
    justifyContent: (props) => (props.addTopMargin === true ? 'center' : 'inherit'),
    alignItems: 'center',
  },
  sectionTitle: {
    marginTop: '20px',
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 1,
  },
  innerContainer: {
    display: 'flex',
    maxWidth: '1260px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '100px',
  },
  headlineContent: {
    padding: '10px 0px',
    fontSize: '18px',
    margin: '0',
  },
  listContent: {
    fontSize: '18px',
    lineHeight: '30px',
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 600,
  },
}));

const AboutUsJAC = (props) => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ addTopMargin: props?.addTopMargin, websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.innerContainer}>
        <img src={JAC_LOGO} alt="Mekor JAC Logo" style={{ height: '100px', width: 'auto' }} />
        <div
          className={classes.titleContainer}
          style={{ paddingBottom: '50px', justifyContent: 'center' }}
        >
          <hr style={{ width: '100%', height: 2, backgroundColor: 'lightGrey', border: 'none' }} />
          <Typography className={classes.title} style={{ textAlign: 'center', width: '400px' }}>
            About Us
          </Typography>
          <hr style={{ width: '100%', height: 2, backgroundColor: 'lightGrey', border: 'none' }} />
        </div>

        <Typography variant="h3" className={classes.sectionTitle}>
          <div style={{ color: websiteColors.primaryColor }}>〉〉</div>
          THE VISION
        </Typography>

        <p className={classes.headlineContent} style={{ textAlign: 'left' }}>
          We as JAC Motors South Africa is dedicated to add value at new levels in the South African
          market, where we deliver quality and reliable products at very affordable prices. With an
          exceptional footprint in terms of a sales and aftersales support network through the whole
          of South Africa.
        </p>

        <p className={classes.headlineContent} style={{ textAlign: 'left' }}>
          JAC Global is dedicated to the progress of China’s automobile industry and committed to
          developing into a leading Chinese automaker with international competitiveness. JAC is
          dedicated to developing and producing safe, environmentally friendly, economical and
          energy-saving vehicles on the basis of sustained brand progress and cost improvement.
        </p>

        <Typography className={classes.sectionTitle}>
          <div style={{ color: websiteColors.primaryColor }}>〉〉</div> WORLD CLASS
        </Typography>

        <p className={classes.headlineContent} style={{ textAlign: 'left' }}>
          Implementation of quality management standards with scientific, advanced and perfected
          testing equipment and methods, ensuring JAC products reach world-class standards.
        </p>
        <p className={classes.headlineContent} style={{ textAlign: 'left' }}>
          JAC adheres to the central ideology of “quality is the foundation of survival and
          development”. Under the quality guideline of “first-class products, advanced technology,
          continuous improvement and sincere service to achieve customer satisfaction”
        </p>

        <Typography className={classes.sectionTitle}>
          <div style={{ color: websiteColors.primaryColor }}>〉〉</div> JAC MOTORS (GLOBAL)
        </Typography>

        <ul>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            Anhui Jianghuai Automobile Group Corp., Ltd. (hereinafter called JAC) was founded in
            1964 and listed on the Shanghai Stock Exchange (600418) in 2001. More than 50 years of
            automotive manufacturing experience.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            JAC Motors is a Chinese state-owned automobile and commercial vehicle manufacturer. The
            company is based in Hefei, Anhui Province, China.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            JAC owns a national-level Research and Development (R&D) Centre in Hefei. The company
            set up China’s first overseas R&D Centre in Turin, Italy, in June 2005, and a second one
            in Tokyo, Japan, in October, 2006.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            In 2010, it was one of the top ten most-productive vehicle manufacturers in China
            selling 458,500 units for 2.5% market share and reaching eighth place. Estimated
            production capacity is over 500,000 units/year as of 2009.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            VOLKSWAGEN has sent its experts to work with JAC R&D team, and a group of more than 30
            staffs are jointly designing and developing the first car of joint venture.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            Aiming to become a world-class brand, JAC is ready to enhance its superiority in
            technology, manufacturing, brand image and sales network through rapid development. JAC
            is dedicated to developing and producing safe, environmentally friendly, economical and
            energy-saving vehicles on the basis of sustained brand progress and cost improvement.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            JAC is dedicated to strict quality control and constant management improvement, because
            of which, JAC has been awarded as ‘Recommended Brand for Export’, ‘The Most Competitive
            Brand in Market’, ‘Enterprise for Exemption from Export Inspection’, ‘National Quality
            Award’ respectively by China Chamber of Commerce.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            JAC has reinforced the consciousness of “quality first” created a quality culture of
            “emphasizing quality and the execution” to ensure product quality is rooted in their
            employees’ consciousness. Adheres to the central ideology of “quality is the foundation
            of survival and development”. Under the quality guideline of “first-class products,
            advanced technology, continuous improvement and sincere service to achieve customer
            satisfaction”
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            By now, JAC has exported products to the regions of South America, Europe, Africa, Asia
            and established friendly relationship with more than 130 countries around the world.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            JAC has achieved an annual production capacity of 1,000,000 units.
          </li>
          <li className={classes.listContent} style={{ textAlign: 'left' }}>
            To read more on JAC Global please visit http://jacen.jac.com.cn
          </li>
        </ul>
      </div>

      {/* <Staff /> */}
    </div>
  );
};

export default AboutUsJAC;
