import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import Logo from '../../components/shared/assets/jac/logo_jac.png';

const useStyles = makeStyles(() => ({
  rootFirst: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: 'white',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px',
    },
  },
  logoImg: {
    width: '10%',
    minWidth: '180px',
    margin: '0 auto',
    display: 'block',
    marginBottom: '20px',
  },
  innerHolder: {
    width: '100%',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  heading: {
    color: 'black',
    marginTop: '21px',
    marginBottom: '10px',
    fontSize: '24px',
    textAlign: 'center',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'grey',
    textAlign: 'center',
  },
  headingTextAccent: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    textAlign: 'center',
  },
  linkText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: {
    display: 'block',
    margin: '0px auto',
  },
  socialLinkHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialLink: {
    width: 'calc(100%/11) !important',
    color: ({ websiteColors }) => `${websiteColors.primaryColor} !important`,
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    },
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
    color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shortDivider: {
    width: '80%',
    minHeight: '10px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
  },
  fullDivider: {
    width: '100%',
    minHeight: '50px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
  },
}));

const FooterJAC = () => {
  const { globalDealer, dealerList, websiteColors, dealerSocials } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [dealer, setDealer] = useState({});

  useEffect(() => {
    if (globalDealer && dealerList) {
      const currentDealer = dealerList.filter((v) => v.dealerId === globalDealer.dealerId);
      setDealer(currentDealer[0]);
    }
  }, [dealerList]);

  return (
    <>
      <div className={classes.dividerContainer}>
        <div className={classes.shortDivider} />
      </div>

      <div className={classes.rootFirst}>
        <Link to="/">
          <img alt="jac_logo" className={classes.logoImg} src={Logo} />
        </Link>

        <div className={classes.innerHolder}>
          <div className={classes.section}>
            <Typography variant="h1" className={classes.headingText}>
              Follow us on
            </Typography>

            <div className={classes.socialLinkHolder}>
              {dealerSocials?.facebook && (
                <div className={classes.socialLink}>
                  <a href={dealerSocials.facebook} target="_blank" rel="noreferrer">
                    <FacebookIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}

              {dealerSocials?.instagram && (
                <div className={classes.socialLink}>
                  <a href={dealerSocials.instagram} target="_blank" rel="noreferrer">
                    <InstagramIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}

              <div className={classes.socialLink}>
                <a
                  href="https://www.youtube.com/channel/UC_sOildmbzD92yq5xzafO0g"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon className={classes.socialLinkIcon} />
                </a>
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div>
              <Typography variant="h1" className={classes.heading}>
                <strong>{globalDealer?.name}</strong>
              </Typography>
              <Typography variant="h1" className={classes.headingText}>
                {globalDealer?.address?.streetAddress}
              </Typography>
              <Typography variant="h1" className={classes.headingText}>
                Tel: {dealer?.newCarsPhoneNumber}
              </Typography>
            </div>
            <div>
              <Typography variant="h1" className={classes.headingTextAccent}>
                <strong>{globalDealer?.email}</strong>
              </Typography>
            </div>
          </div>

          <div className={classes.section}>
            <Typography variant="h1" className={classes.heading}>
              <strong>JAC Motors Legal</strong>
            </Typography>

            <Link to="/contact-us">
              <Typography variant="h1" className={classes.linkText}>
                Contact Us
              </Typography>
            </Link>
            <Link to="/cookies">
              <Typography variant="h1" className={classes.linkText}>
                Website Usage
              </Typography>
            </Link>
            <Link to="/privacy">
              <Typography variant="h1" className={classes.linkText}>
                Privacy Policy
              </Typography>
            </Link>
            <Link to="/terms">
              <Typography variant="h1" className={classes.linkText}>
                Terms & Conditions
              </Typography>
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.dividerContainer}>
        <div className={classes.fullDivider} />
      </div>
    </>
  );
};

export default FooterJAC;
