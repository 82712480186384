import React, { useContext } from 'react';
import { DealerContext } from '../shared/contexts/DealerContext';

const WAFAB = () => {
  const { globalDealer } = useContext(DealerContext);

  return (
    <div className="fixed flex-col items-center justify-center bottom-20 right-10 h-14 w-24 z-50 max-md:h-6 max-md:bottom-16 max-md:right-1">
      <div>
        <div className="bg-white shadow-lg md:rounded-md rounded-l-md right-10">
          <a
            className="flex flex-col h-full w-36 text-center items-center right-10 justify-center z-50 py-4 text-black hover:underline hover:text-[#f51638]"
            target="_blank"
            href={`https://wa.me/27814695936?text=Good day. I am interested in booking a ${globalDealer?.relatedBrands[0].name} test drive.`}
            rel="noopener noreferrer"
          >
            <img
              className="h-14 w-14"
              alt="Contact Mekor Honda WhatsApp button"
              src="/Whatsapp_Logo.png"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default WAFAB;
