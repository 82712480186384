import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  greyBar: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'end',
    padding: '6px 15px',
  },
  greyBarText: {
    padding: '0px 10px',
    '@media (max-width: 768px)': {
      fontSize: '13px!important',
    },
  },
  greyBarOuter: {
    display: 'flex',
    background: '#00000033',
    width: '100%',
    justifyContent: 'center',
    boxShadow: '0 1px 4px #001e4c70',
  },
  greyBarLeft: {
    display: 'flex',
  },
  menuHodlerOuter: {
    width: '100%',
    boxShadow: '0px 0px 43px -3px #00000042',
    position: 'sticky',
    paddingLeft: '0px',
    display: 'flex',
    justifyContent: 'center',
    top: '0px',
    zIndex: '55',
    background: 'white',
  },
  menu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      padding: '5px 10px',
    },
  },
  menuHodler: {
    display: 'flex',
    '@media (max-width: 1000px)': {
      display: 'none',
    },
  },
  Link: {
    color: '#274b84',
    fontSize: '14px',
    '&:hover': {
      color: '#2c88d9',
      cursor: 'pointer',
    },
    padding: '19px 10px',
    '@media (min-width: 1001px) and (max-width: 1038px)': {
      padding: '16px 8px',
      fontSize: '12px',
    },
  },
  AfterSalesDropLink: {
    padding: '19px 30px',
    color: '#274b84',
    fontSize: '14px',
    '&:hover': {
      color: '#2c88d9',
      cursor: 'pointer',
    },
  },
  LinkM: {
    padding: '15px 15px',
    color: '#274b84',
    '&:hover': {
      color: '#2c88d9',
      cursor: 'pointer',
    },
  },
  BurgerHolder: {
    position: 'relative',
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
      position: 'relative',
    },
  },
  Burger: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
    },
  },
  pattie: {
    minWidth: '20px',
    minHeight: '2px',
    margin: '4px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  img: {
    width: '190px',
    padding: '20px',
    '@media (max-width: 768px)': {
      width: '180px',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '200px',
    },
  },
  dropDown: {
    position: 'fixed',
    width: '258px',
    right: '0px',
    background: 'white',
    padding: '10px',
    overflow: 'auto',
    height: '100vh',
    zIndex: '3',
  },
  showRoomDropDownHolder: {
    position: 'relative',
  },
  showRoomDropDown: {
    position: 'absolute',
    background: 'white',
    whiteSpace: 'nowrap',
  },
  AfterSalesDrop: {
    position: 'absolute',
    background: 'white',
  },
  AfterSalesDropM: {
    background: 'white',
  },
}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuAfterSales, setOpenMenuAfterSales] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showRoomDropDown, setShowRoomDropDown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const [Logo, setLogo] = useState('');
  const classes = useStyles({ websiteColors });
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerWebsites/${globalDealer.dealerId} `,
    }).then((response) => {
      setLogo(response.data.image);
    });
  }, [globalDealer]);
  const handleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const handleMenuAfterSales = () => {
    if (openMenuAfterSales) {
      setOpenMenuAfterSales(false);
    } else {
      setOpenMenuAfterSales(true);
    }
  };
  // MIGHT NOT NEED TERNARY
  return (
    <>
      <Box className={classes.menuHodlerOuter}>
        <Box className={classes.menu}>
          <div className={classes.img}>
            <Link
              to="/"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setShowAboutMenu(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <img alt="" src={Logo} width="100%" />
            </Link>
          </div>
          <div className={classes.BurgerHolder}>
            <div
              className={classes.Burger}
              onClick={() => {
                handleMenu();
              }}
            >
              <div className={classes.pattie} />
              <div className={classes.pattie} />
              <div className={classes.pattie} />
            </div>
          </div>
          <Box className={classes.menuHodler}>
            <Link
              to="/our-brands"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Our Brands</Typography>
            </Link>
            <Link
              to="/used-vehicles"
              onMouseOver={() => {
                setShowRoomDropDown(false);
              }}
            >
              <Typography className={classes.Link}>Mekor Approved</Typography>
            </Link>
            <Link
              to="/special-deals"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Specials</Typography>
            </Link>
            <Link
              to="/service"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Book A Service</Typography>
            </Link>
            <Link
              to="/apply-for-finance"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Financial Services</Typography>
            </Link>
            <a href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer">
              <Typography className={classes.Link}>Sell My Car</Typography>
            </a>
            <Link
              to="/contact-us"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setShowAboutMenu(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Contact Us</Typography>
            </Link>
            <Link
              to="/find-a-dealer"
              onMouseOver={() => {
                setShowRoomDropDown(false);
                setShowAboutMenu(false);
                setOpenMenuAfterSales(false);
              }}
            >
              <Typography className={classes.Link}>Find A Dealer</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      {openMenu && (
        <div className={classes.dropDown}>
          <Link to="/">
            <Typography
              className={classes.LinkM}
              onClick={() => {
                handleMenu();
              }}
            >
              Home
            </Typography>
          </Link>
          <Link
            to="/our-brands"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Our Brands</Typography>
          </Link>
          <Link
            to="/used-vehicles"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Mekor Approved</Typography>
          </Link>
          <Link
            to="/special-deals"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Special Deals</Typography>
          </Link>
          <div
            onMouseOver={() => {
              setShowRoomDropDown(false);
            }}
            onClick={() => {
              handleMenuAfterSales();
            }}
          >
            <Typography className={classes.LinkM}>After Sales</Typography>
            {openMenuAfterSales ? (
              <div className={classes.AfterSalesDropM}>
                <Link
                  to="/parts"
                  onMouseOver={() => {
                    setShowRoomDropDown(false);
                  }}
                >
                  <Typography className={classes.AfterSalesDropLink}>Parts</Typography>
                </Link>
                <Link
                  to="/service"
                  onMouseOver={() => {
                    setShowRoomDropDown(false);
                  }}
                >
                  <Typography className={classes.AfterSalesDropLink}>Service</Typography>
                </Link>
                <Link
                  to="/accessories"
                  onMouseOver={() => {
                    setShowRoomDropDown(false);
                  }}
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.AfterSalesDropLink}>Accessories</Typography>
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>
          <Link
            to="/apply-for-finance"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Apply Online</Typography>
          </Link>
          <a href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer">
            <Typography className={classes.LinkM}>Sell My Car</Typography>
          </a>
          <Link
            to="/about"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>About Us</Typography>
          </Link>
          <Link
            to="/events"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Events</Typography>
          </Link>
          <Link
            to="/accessories"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Accessories</Typography>
          </Link>
          <Link
            to="/contact-us"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Contact Us</Typography>
          </Link>
          <Link
            to="/find-a-dealer"
            onClick={() => {
              handleMenu();
            }}
          >
            <Typography className={classes.LinkM}>Find A Dealer</Typography>
          </Link>
        </div>
      )}
    </>
  );
};

export default TopBar;
