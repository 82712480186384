import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { GAInit } from '@red-build/leadcentre';
import { BASE_URI, DEALERID, MOTORGROUPID, MASTER_DEALER_NAME } from '../Constants';

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {
  const [globalDealer, setGlobalDealer] = useState({});
  const [websiteColors, setWebsiteColors] = useState({});
  const [dealerList, setDealerList] = useState();
  const [dealerListDirty, setDealerListDirty] = useState();
  const [loading, setLoading] = useState(false);
  const [dealerSocials, setDealerSocials] = useState({});
  const [dealerWebsite, setDealerWebsite] = useState({});

  const defaultColors = {
    primaryColor: '#F5ED32',
    secondaryColor: '#d6ebf9',
    accentColor: '#ffffff',
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {})
      .then((result) => {
        const filtered = result.data
          .filter(
            (d) =>
              d.name !== MASTER_DEALER_NAME &&
              (!d.name.includes('Master') || d.name.includes('Motorcycle'))
          )
          // temp workaround to not have to break cloudfront url.
          // todo remove this once the new honda sites go live.
          .map((d) => ({
            ...d,
            websiteUrl: d.dealerId !== 554 ? d.websiteUrl : 'https://www.hondacanalwalk.co.za/',
          }));
        const filterDealer = filtered.filter((d) => !(d.dealerId === 633));
        setDealerList(filterDealer);
        setDealerListDirty(result.data);
      });
  }, []);

  const lookupDealer = async (name) => {
    await axios({
      method: 'GET',
      url: `${BASE_URI}/Dealers/${MOTORGROUPID}/${name}`,
    })
      .then((response) => {
        const socials = response.data.social;
        if (socials.googleUniversalAnalyticsCode.length > 0) {
          GAInit(socials.googleUniversalAnalyticsCode);
        }
        setDealerSocials(response.data.social);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('ERROR Failed at LookupDealer', error);
      });
  };

  useEffect(() => {
    if (dealerListDirty) {
      const { host } = window.location;
      // LEAVING THIS IN FOR DEBUGGING
      // const host = 'mekorjac.co.za';
      // const host = 'mekorbaic.co.za';
      // const host = 'mekoromoda.co.za';
      const foundDealer = dealerListDirty.find(
        (v) => v.websiteUrl?.includes(host) || host.includes(v?.websiteUrl)
      );

      if (foundDealer) {
        lookupDealer(foundDealer.name);
        setGlobalDealer(foundDealer);
      } else {
        const masterDealer = dealerListDirty.find((v) => v?.dealerId === DEALERID);
        lookupDealer(masterDealer.name);
        setGlobalDealer(masterDealer);
      }
    }
  }, [dealerListDirty]);

  useEffect(() => {
    if (globalDealer?.dealerId) {
      const getWebsiteColors = async (dealerID) => {
        if (!globalDealer.websiteColors) {
          try {
            const result = await axios({
              method: 'GET',
              url: `${BASE_URI}/DealerWebsites/Colors/${dealerID}`,
            });

            if (result?.data) {
              const colorsObject = result?.data;
              setWebsiteColors(colorsObject || {});
            } else {
              getWebsiteColors(DEALERID);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Failed fetching websiteColors', error);
            setLoading(false);
          }
        } else {
          setWebsiteColors(globalDealer.websiteColors);
        }
      };

      const getDealerWebsites = async (dealerID) => {
        try {
          const result = await axios({
            method: 'GET',
            url: `${BASE_URI}/DealerWebsites/${dealerID}`,
          });
          const websites = result?.data;
          setDealerWebsite(websites || {});
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('Failed fetching websiteColors', error);
          setLoading(false);
        }
      };

      getWebsiteColors(globalDealer?.dealerId);
      getDealerWebsites(globalDealer?.dealerId);
    }
  }, [globalDealer]);

  useEffect(() => {
    if (
      !websiteColors ||
      !websiteColors?.primaryColor ||
      !websiteColors?.secondaryColor ||
      !websiteColors?.accentColor
    ) {
      setWebsiteColors({
        ...websiteColors,
        primaryColor: websiteColors?.primaryColor
          ? websiteColors?.primaryColor
          : defaultColors.primaryColor,
        secondaryColor: websiteColors?.secondaryColor
          ? websiteColors?.secondaryColor
          : defaultColors.secondaryColor,
        accentColor: websiteColors?.accentColor
          ? websiteColors?.accentColor
          : defaultColors.accentColor,
      });
    }
    setLoading(false);
  }, [websiteColors]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DealerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        loading,
        globalDealer,
        websiteColors,
        dealerList,
        dealerListDirty,
        dealerSocials,
        dealerWebsite,
      }}
    >
      {children}
    </DealerContext.Provider>
  );
};
