import React, { useEffect, useState, useContext } from 'react';
import {
  //  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { ContactUs } from '@red-build/leadcentre';
import ShowRoom from '../../components/Showroom/Showroom';
import Carousel from '../../components/shared/Carousel/SharedCarousel';
import { BASE_URI, MASTER_DEALERID, BAIC_DEALER_ID } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
// import BackgroundImg from '../../components/shared/assets/car.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#fff',
    backgroundAttachment: 'inherit',
  },
  slidesPlaceholderContainer: {
    height: '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionContainerOuterTop: {
    paddingTop: '100px',
    '@media (max-width: 500px)': {
      padding: '25px 0',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      padding: '50px 0',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      padding: '100px 0',
    },
  },
  sectionContainerOuterMiddel: {
    padding: '100px 0',
    '@media (max-width: 500px)': {
      padding: '25px 0',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      padding: '50px 0',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      padding: '100px 0',
    },
  },
  sectionContainerOuterBottom: {
    paddingBottom: '100px',
    '@media (max-width: 500px)': {
      padding: '25px 0',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      padding: '50px 0',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      padding: '100px 0',
    },
  },
  sectionContainerInner: {
    backgroundColor: 'rgb(255 255 255 / 60%)',
    display: 'grid',
  },
  sectionTitleContainer: {
    width: '100%',
    padding: '50px 0 50px 3%',
    '@media (max-width: 400px)': {
      padding: '30px',
    },
    '@media (min-width: 401px) and (max-width: 500px)': {
      padding: '50px',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      padding: '50px 0 50px 3%',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      padding: '75px 0 75px 3%',
    },
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontSize: '45px',
    fontWeight: 600,
    lineHeight: 1,
    color: 'black',
    '@media (max-width: 350px)': {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
    '@media (min-width: 351px) and (max-width: 500px)': {
      fontSize: '2rem',
      textAlign: 'center',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      fontSize: '2.8rem',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      fontSize: '3.5rem',
    },
  },
  sectionContentContainer: {
    width: '100%',
    padding: '0 3%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  sectionTextBlock: {
    width: '48%',
    marginBottom: '20px',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  formContainer: {
    padding: 20,
    width: '40%',
    justifySelf: 'center',
    '@media (max-width: 500px)': {
      width: '90%',
    },
    '@media (min-width: 501px) and (max-width: 800px)': {
      width: '75%',
    },
    '@media (min-width: 801px) and (max-width: 1025px)': {
      width: '60%',
    },
  },
}));

const HomeComponent = () => {
  let cancel;
  const classes = useStyles();
  const { loading, globalDealer } = useContext(DealerContext);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (loading) return;
    const params = {
      dealerId: BAIC_DEALER_ID,
      pageNumber: 1,
      pageSize: 25,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
        buttonText: itm.buttonText,
      }));

      setSlides(tempSlides.reverse());
    });
    // eslint-disable-next-line consistent-return
    return () => cancel();
  }, [loading]);

  return (
    <div className={classes.root}>
      {slides?.length > 0 ? <Carousel slides={slides} /> : null}
      <section id="models" className={classes.sectionContainerOuterTop}>
        <div className={classes.sectionContainerInner}>
          <ShowRoom />
        </div>
      </section>
      <section
        id="about-us"
        className={classes.sectionContainerOuterMiddel}
        style={{ paddingTop: 0 }}
      >
        <div className={classes.sectionContainerInner}>
          <div className={classes.sectionTitleContainer}>
            <Typography className={classes.sectionTitle}>About Us</Typography>
          </div>

          <div className={classes.sectionContentContainer}>
            <div className={classes.sectionTextBlock}>
              <Typography variant="h6" align="justify" gutterBottom>
                BAIC is a leading automotive manufacturer that boasts attention to detail, comfort
                and excellence across the range. Founded in 1958, BAIC has perfected the craft of
                delivering quality to drivers through user friendly features and outstanding
                performance, both on and off-road.
              </Typography>
            </div>
            <div className={classes.sectionTextBlock}>
              <Typography variant="h6" align="justify" gutterBottom>
                Known for exceptional customer satisfaction and bringing vehicles of value to
                drivers, BAIC sparks a passion for exploration and innovation.
              </Typography>
              <Typography variant="h6" align="justify" gutterBottom>
                Start a new adventure with BAIC and transform your world.
              </Typography>
            </div>
          </div>
        </div>
      </section>

      <section id="contact-us" className={classes.sectionContainerOuterBottom}>
        <div className={classes.sectionContainerInner}>
          <div className={classes.sectionTitleContainer}>
            <Typography className={classes.sectionTitle}>Contact Us</Typography>
          </div>

          <div className={classes.formContainer}>
            <ContactUs
              leadTypeId={3}
              base_uri={BASE_URI}
              masterDealerId={MASTER_DEALERID}
              dealerId={BAIC_DEALER_ID}
              globalDealerId={globalDealer?.dealerId}
              dealerName={globalDealer?.name?.replace('Master', '')}
              dealerType="single_dealer"
              websiteColors={{
                primaryColor: '#d1241a',
                secondaryColor: '#000000',
                accentColor: '#000000',
              }} // Importing WebsiteColors from context does not work as expected.
              note
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeComponent;
