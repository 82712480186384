import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '-10px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    bottom: 0,
    zIndex: 10,
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px',
    },
  },
  innerHolder: {
    width: '100%',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
    zIndex: 4,
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  sectionFlex: {
    display: 'flex',
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  sectionInnerGroup: {
    width: 'calc(100%/2)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '10px',
    fontSize: '24px',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  versionText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: '#ffffff90',
  },
  img: {
    display: 'block',
    margin: '0px auto',
  },
  imgSection: {
    width: 'calc(100%/3)',
    textAlign: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  hr: {
    border: '1px #6c757d70 solid',
    width: '100%',
  },
  legal: {
    fontSize: '8pt',
  },
  socialLinkHolder: {
    display: 'flex',
  },
  socialLink: {
    width: 'calc(100%/11)!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    },
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  },
  LogoHolder: {
    marginTop: '50px',
    display: 'block',
  },
}));

const Footer = () => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.heading}>
            <strong>Legal </strong>
          </Typography>
          <Link to="/popi">
            <Typography variant="h1" className={classes.headingText}>
              Popia
            </Typography>
          </Link>
          <Link to="/terms">
            <Typography variant="h1" className={classes.headingText}>
              Terms & Conditions
            </Typography>
          </Link>
          <Link to="/cookies">
            <Typography variant="h1" className={classes.headingText}>
              Cookie Policy
            </Typography>
          </Link>
        </div>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.heading}>
            <strong>Tools</strong>
          </Typography>
          <Link to="/our-brands">
            <Typography variant="h1" className={classes.headingText}>
              Our Brands
            </Typography>
          </Link>
          <Link to="/service">
            <Typography variant="h1" className={classes.headingText}>
              Book A Service
            </Typography>
          </Link>
          <Link to="/special-deals">
            <Typography variant="h1" className={classes.headingText}>
              Our Special Deals
            </Typography>
          </Link>
        </div>
        <div className={classes.GroupedSection}>
          <div>
            <Typography variant="h1" className={classes.heading}>
              <strong>Financial Services </strong>
            </Typography>
            <Link to="/apply-for-finance">
              <Typography variant="h1" className={classes.headingText}>
                Apply For Finance
              </Typography>
            </Link>
          </div>
        </div>
        <div className={classes.GroupedSection}>
          <Typography variant="h1" className={classes.heading}>
            <strong>Contact</strong>
          </Typography>
          <div>
            <Link to="/contact-us">
              <Typography variant="h1" className={classes.headingText}>
                Contact Us
              </Typography>
            </Link>
            <Link to="/find-a-dealer">
              <Typography variant="h1" className={classes.headingText}>
                Find A Dealer
              </Typography>
            </Link>
          </div>
        </div>
      </div>
      <hr style={{ width: '100%', backgroundColor: 'grey' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography className={classes.versionText}>
          © Copyright {new Date().getFullYear()} | Mekor Motor Group | v
          {process.env.REACT_APP_BUILD_NUMBER || 'DEV'}
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
