import React, { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Service } from '@red-build/leadcentre';
import { BASE_URI, MOTORGROUPID, MASTER_DEALERID } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '60px',
    marginBottom: '60px',
  },
  innerSection: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 15px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '6px 0px',
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
}));

const BookService = () => {
  const { globalDealer, websiteColors, dealerList } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            BOOK A
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            SERVICE
          </Typography>
          <Typography variant="body2" className={classes.text}>
            We will happily assist in the service of your vehicle. Leave your details, and one of
            our service advisors will contact you shortly.
          </Typography>
        </div>
        <div className={classes.section}>
          <Service
            masterDealerId={MASTER_DEALERID}
            base_uri={BASE_URI}
            dealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name}
            leadTypeId={5}
            motorGroupId={MOTORGROUPID}
            dealerList={dealerList}
            activeMake={
              globalDealer?.relatedBrands.length > 1 ? '' : globalDealer?.relatedBrands[0].name
            }
            activeDealerId={globalDealer?.dealerId}
            activeMakeId={
              globalDealer?.relatedBrands.length > 1 ? 0 : globalDealer?.relatedBrands[0].id
            }
            dealerMakesOnly
            websiteColors={websiteColors}
          />
        </div>
      </div>
    </div>
  );
};

export default BookService;
