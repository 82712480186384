import React, { useContext, useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, Link } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext';
import { ReactComponent as LoadingIcon } from '../shared/assets/loading.svg';

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px 0px',
  },
  img: {
    maxWidth: '100%',
    width: 'auto',
  },
  holderFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80%',
    margin: '0 auto',
    position: 'relative',
    flexWrap: 'wrap',
  },
  holder: {
    flexDirection: 'column',
    width: 'calc(100% / 5 - 40px)',
    margin: '20px',
    height: 'calc(100vh / 4 - 40px)',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    border: '1px solid #6f6f6f',
    cursor: 'pointer',
    borderRadius: '5px',
    '@media (max-width: 613px)': {
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100%',
    },
    '@media (min-width: 1024px) and (max-width: 1600px)': {
      minWidth: 'calc(100% / 4 - 40px)',
      maxWidth: 'calc(100% / 4 - 40px)',
    },
    '@media (min-width: 614px) and (max-width: 1023px)': {
      minWidth: 'calc(100% / 2 - 40px)',
      maxWidth: 'calc(100% / 2 - 40px)',
    },
  },
  HeadingHolder: {
    display: 'flex',
    justifyContent: 'center',
  },
  HeadingBlue: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    width: '80%',
    margin: '0 auto',
    zIndex: 2,
    textTransform: 'uppercase',
  },
  HeadingBlueOverlay: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    right: '0',
    pointerEvents: 'none',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    },
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
}));

const SpecialDealersList = () => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const { dealerList } = useContext(DealerContext);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState({});
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    if (params.brand && dealerList) {
      const afilteredDealers = dealerList.filter((v) =>
        v.relatedBrands.some((va) => va.name === params.brand)
      );
      setSelectedBrand(afilteredDealers[0].relatedBrands[0]);
      setSlides(afilteredDealers);
    }

    setLoading(false);
  }, [dealerList]);

  const handleDealerChange = (dealer) => {
    if (Object.keys(dealer).length > 0) {
      switch (dealer?.name) {
        case 'Mekor Honda Canal Walk':
        case 'Mekor Honda Cape Town':
        case 'Mekor Honda Claremont':
        case 'Mekor Honda Tygerberg': {
          window.location.href = `${dealer?.websiteUrl}specials/`;
          break;
        }
        case 'Mekor Honda Motorcycles': {
          window.location.href = `https://www.hondadealer.co.za/specials/`;
          break;
        }
        default: {
          window.location.href = `${dealer?.websiteUrl}`;
          break;
        }
      }
    }
    setLoading(false);
  };

  if (!loading && slides.length > 0) {
    return (
      <div className={classes.root}>
        <div className={classes.HeadingHolder}>
          <button
            style={{
              borderRadius: 20,
              width: 40,
              height: 40,
              borderColor: 'transparent',
              backgroundColor: 'blue',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              padding: 6,
              margin: 4,
            }}
            type="button"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <Link to="/our-brands">
              <ArrowBackIcon
                style={{ alignSelf: 'center', color: 'whitesmoke', padding: 0, margin: 0 }}
              />
            </Link>
          </button>
          <Typography variant="h1" className={classes.titleTopAccent}>
            Our {selectedBrand?.name} Dealers
          </Typography>
        </div>
        <div className={classes.holderFlex}>
          {slides.length > 0
            ? slides.map((slide) => (
                <div
                  key={slide?.name}
                  className={classes.holder}
                  onClick={() => {
                    setLoading(true);
                    handleDealerChange(slide);
                  }}
                >
                  <img alt={selectedBrand?.name} src={selectedBrand?.logo} width="80%" />
                  <Typography>{slide?.name}</Typography>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.HeadingHolder}>
        <Typography variant="h1" className={classes.titleTopAccent}>
          Our Dealers
        </Typography>
      </div>
      <div className={classes.holderFlex}>
        <LoadingIcon style={{ width: 150, height: 150, alignSelf: 'center' }} />
      </div>
    </div>
  );
};

export default SpecialDealersList;
