import React, { useContext } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer';
import HomeComponent from '../HomeComponent/HomeComponent';
import AboutUs from '../../components/AboutUs/AboutUs';
import Showroom from '../../components/Showroom/Showroom';
import DetailedVehicle from '../../components/DetailedVehicle/DetailedVehicle';
import UsedVehicles from '../../components/UsedVehicles/UsedVehicles';
import DetailedUsedVehicle from '../../components/DetailedUsedVehicle/DetailedUsedVehicle';
import Aftersales from '../../components/AfterSales/AfterSales';
import Service from '../../components/BookService/BookService';
import Parts from '../../components/Parts/Parts';
import VehicleFinance from '../../components/Finance/VehicleFinance';
import SellMyCar from '../SellMyCar/SellMyCar';
import ContactUs from '../../components/ContactUs/ContactUs';
import Cookies from '../../components/Legal/Cookies';
import Popi from '../../components/Legal/Popia';
import TermsAndConditions from '../../components/Legal/Terms';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import SpecialDeals from '../../components/SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from '../../components/DetailedSpecialDeals/DetailedSpecialDeals';
import TestDrive from '../../components/BookATestDrive/BookATestDrive';

const DashboardContainerBaic = () => {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();

  return (
    <main>
      <TopBar />
      <div style={{ minHeight: '70vh', overflowY: 'scroll' }}>
        <Route exact path={['/', '/home']} component={HomeComponent} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/show-room" component={Showroom} />
        <Route path="/show-room/:model/:stockId/" component={DetailedVehicle} />
        <Route exact path="/pre-owned" component={UsedVehicles} />
        <Route path="/pre-owned/:stockId" component={DetailedUsedVehicle} />
        <Route exact path="/aftersales" component={Aftersales} />
        <Route exact path="/parts" component={Parts} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/apply-for-finance" component={VehicleFinance} />
        <Route exact path="/sell-my-car" component={SellMyCar} />
        <Route
          exact
          path="/contact-us"
          render={() => <ContactUs heading="CONTACT" subHeading="US" />}
        />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/privacy" component={Popi} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <Route exact path="/special-deals" component={SpecialDeals} />
        <Route exact path="/special-deals/:dealId" render={() => <DetailedSpecialDeals />} />
        <Route exact path="/book-a-testdrive" component={TestDrive} />
        <Route exact path="/book-a-testdrive/:stockId" component={TestDrive} />
      </div>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} />
    </main>
  );
};

export default DashboardContainerBaic;
