import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Finance } from '@red-build/leadcentre';
// import axios from 'axios';
import { MASTER_DEALERID, BASE_URI, FINAPP_URI_APPLICATION } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 15px',
    backgroundColor: 'white',
  },
  innerSection: {
    width: '100%',
    maxWidth: '1260px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
  imgBlockHolder: {
    color: '#b81b22',
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: '10px',
    maxWidth: '490px',
    margin: 'auto',
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const VehicleFinance = () => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1">
            <div
              className={classes.titleTop}
              style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
            >
              NEED
            </div>
            <div
              className={classes.titleTopAccent}
              style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
            >
              FINANCE?
            </div>
          </Typography>
          <Typography
            variant="body2"
            className={classes.text}
            style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
          >
            Leave your details, and we will get back to you.
          </Typography>
        </div>
        <div className={classes.section}>
          <Finance
            leadTypeId={1}
            masterDealerId={MASTER_DEALERID}
            dealerId={globalDealer.dealerId}
            pqType="interim_pq"
            // dealerModels={dealerModels}
            activeDealerId={globalDealer.dealerId}
            dealers={dealerList}
            base_uri={BASE_URI}
            dealerType="multi_dealer"
            pqSuccessUrl={FINAPP_URI_APPLICATION}
            dealerMakesOnly
            activeMakeId={
              globalDealer?.relatedBrands.length > 1 ? 0 : globalDealer?.relatedBrands[0].id
            }
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleFinance;
