import React, { useContext } from 'react';
import { SellYourVehicle } from '@red-build/leadcentre';
import { makeStyles } from '@material-ui/core';
import { BASE_URI, WEPAY4CARS_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '70px',
    padding: '100px 15px',
  },
  imgHolder: {
    maxWidth: '1170px',
  },
  innerSection: {
    maxWidth: '1170px',
  },
}));

const SellVehicle = () => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles();
  const defaultColors = {
    primaryColor: websiteColors.primaryColor,
    secondaryColor: 'pink',
    accentColor: websiteColors.accentColor,
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <SellYourVehicle
          base_uri={BASE_URI}
          wePay4Cars_uri={WEPAY4CARS_URI}
          dealerId={globalDealer.dealerId}
          active
          leadSourceId={15}
          websiteColors={defaultColors}
          dealerList={dealerList}
        />
      </div>
    </div>
  );
};

export default SellVehicle;
