/* eslint-disable no-plusplus */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';
import { ReactComponent as LoadingIcon } from '../../components/shared/assets/loading.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      padding: '0px 0px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      padding: '0px 0px',
    },
  },
  innerHolder: {
    display: 'flex',
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: '20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: '20px 20px',
    },
  },
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: 'calc(100% / 3 - 40px )',
    minHeight: '100%',
    margin: '0px 20px',
    padding: '25px 20px',
    paddingBottom: '100px',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      minWidth: '100%',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      minWidth: 'calc(100% / 2 - 20px )',
      margin: '10px',
    },
  },
  cardTextHolder: {
    display: 'flex',
    width: '75%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    height: '30%',
    padding: '0px',
    color: 'white',
    '@media (max-width: 768px)': {},
  },
  cardTextTitleHolder: {
    marginBottom: '10px',
    width: '100%',
    textAlign: 'center',
  },
  imageHolder: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    minHeight: '70%',
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    margin: '10px 0px',
  },
  cardButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: 'none',
    cursor: 'pointer',
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext);
  const [vehicles, setVehicles] = useState([]);
  const classes = useStyles({ websiteColors });
  const handleRedirect = (data) => {
    if (data.isComingSoon) {
      history.push(`/coming-soon/${data?.make}/${data?.ownedModelId}`, data);
    } else {
      history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data);
    }
  };
  const aVehiclePriority = ['X200 Double Cab', 'X200 Single Cab', 'N56', 'T6', 'T8', 'T9'];

  useEffect(() => {
    // Sort vehicles
    const aVehicles = newVehiclesList.sort((a, b) => {
      const aIndex = aVehiclePriority.indexOf(a?.title);
      const bIndex = aVehiclePriority.indexOf(b?.title);

      return aIndex - bIndex;
    });
    setVehicles(aVehicles);
  }, [newVehiclesList]);

  if (vehicles.length < 1) {
    return (
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        {vehicles.flat().map((v, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`master_vehicle_card_${i}`}
            className={classes.card}
            style={{ justifyContent: 'space-between' }}
          >
            <div className={classes.imageHolder}>
              <img alt="" src={v?.image} width="100%" />
            </div>
            <div className={classes.cardTextHolder}>
              <div className={classes.cardTextTitleHolder}>
                <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
              </div>
              <button
                type="button"
                className={classes.cardButton}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <h5 style={{ textAlign: 'center', fontSize: 20, color: 'white', margin: 10 }}>
                  View {v.title}
                </h5>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowRoom;
