/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
let events_data = [];

const url = 'https://c2-leadcentre-bma-group.s3.eu-west-2.amazonaws.com/events';

export default events_data = [
  {
    blog_id: 1,
    title: 'CANCER FUNDRAISER GOLF DAY',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/golfday_event_2023/golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%281%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%282%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%283%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%284%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%285%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%286%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%287%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%288%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%289%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2810%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2811%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2812%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2813%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2814%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2815%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2816%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2817%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2818%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2819%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2820%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2821%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2822%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2823%29.jpg`,
      `${url}/Bma%20Group/golfday_event_2023/golfday%20%2824%29.jpg`,
    ],
  },
  {
    blog_id: 2,
    title: 'DORADO SKI GOLF DAY ',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%281%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%282%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%283%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%284%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%285%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%286%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%287%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%288%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%289%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2810%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2811%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2812%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2813%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2814%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2815%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2816%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2817%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2818%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2819%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2820%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2821%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2822%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2823%29.jpg`,
      `${url}/Bma%20Group/ski_golfday_event_2023/ski_golfday%20%2824%29.jpg`,
    ],
  },
  {
    blog_id: 3,
    title: 'HERVORMDE KERK GOLF DAG ',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%281%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%282%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%283%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%284%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%285%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%286%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%287%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%288%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%289%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2810%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2811%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2812%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2813%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2814%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2815%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2816%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2817%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2818%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2819%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2820%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2821%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2822%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2823%29.jpg`,
      `${url}/Bma%20Group/kerk_golfday_event_2023/kerk_golfday%20%2824%29.jpg`,
    ],
  },
  {
    blog_id: 4,
    title: 'JACKS PAINT GOLF DAY ',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%281%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%282%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%283%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%284%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%285%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%286%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%287%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%288%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%289%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2810%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2811%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2812%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2813%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2814%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2815%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2816%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2817%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2818%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2819%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2820%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2821%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2822%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2823%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2824%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2825%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2826%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2827%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2828%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2829%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2830%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2830%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2832%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2833%29.jpg`,
      `${url}/Bma%20Group/jacks_golfday_event_2023/jacks_golfday%20%2834%29.jpg`,
    ],
  },
  {
    blog_id: 5,
    title: 'INGITION TV',
    date: '05/05',
    dealers: [
      496, // Bma Group
      532, // Mahindra Agri
      500, // Mahindra
    ],
    img: `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(1).jpeg`,
    galleryImages: [
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(1).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(2).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(3).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(4).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(5).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(6).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(7).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(8).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(9).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(10).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(11).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(12).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(13).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(14).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(15).jpeg`,
      `${url}/Bma%20Group/Ingition_tv_event_2023/Ingition%20(16).jpeg`,
    ],
  },
  {
    blog_id: 6,
    title: 'LAERSKOOL RANDFONTEIN GOLF DAY',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(13).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(14).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(15).jpeg`,
      `${url}/Bma%20Group/laerskool_golfday_event_2023/golfday%20(16).jpeg`,
    ],
  },
  {
    blog_id: 7,
    title: 'MAHINDRA M-CARE CAREFEST 2023',
    date: '05/05',
    dealers: [
      496, // Bma Group
      500, // Mahindra
    ],
    img: `${url}/Bma%20Group/mcare_event_2023/mcare%20(1).jpeg`,
    galleryImages: [
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(1).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(1).mov`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(2).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(2).mov`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(3).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(4).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(5).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(6).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(7).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(8).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(9).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(10).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(11).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(12).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(13).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(14).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(15).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(16).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(17).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(18).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(19).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(20).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(21).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(22).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(23).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(24).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(25).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(26).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(27).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(28).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(30).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(31).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(32).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(34).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(35).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(36).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(37).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(38).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(40).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(41).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(42).jpeg`,
      `${url}/Bma%20Group/mcare_event_2023/mcare%20(43).jpeg`,
    ],
  },
  {
    blog_id: 8,
    title: 'RANDFONTEIN YOUTH FUN RUN 2023',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(1).jpeg`,
    galleryImages: [
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(1).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(2).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(3).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(4).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(5).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(6).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(7).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(8).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(9).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(10).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(11).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(12).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(13).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(14).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(14).png`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(15).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(15).png`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(16).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(16).png`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(17).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(17).png`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(18).jpeg`,
      `${url}/Bma%20Group/youth_run_event_2023/youth%20run%20(18).png`,
    ],
  },
  {
    blog_id: 9,
    title: 'RANT EN DAL PRISMA GOLF DAY',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(1).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(1).png`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(2).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(2).png`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(3).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(4).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(5).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(6).JPG`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(7).JPG`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(8).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(9).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(10).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(11).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(12).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(13).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(14).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(15).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(16).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(17).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(18).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(19).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(20).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(21).jpg`,
      `${url}/Bma%20Group/prisma_golfday_event_2023/prisma%20golfday%20(22).jpg`,
    ],
  },
  {
    blog_id: 10,
    title: 'SOLIDARITEIT GOLF DAY',
    date: '05/05',
    dealers: [
      496, // Bma Group
    ],
    img: `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(13).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(14).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(15).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(16).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(17).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(18).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(19).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(20).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(21).jpeg`,
      `${url}/Bma%20Group/solidariteit_golfday_event_2023/golfday%20(22).jpeg`,
    ],
  },
  {
    blog_id: 11,
    title: 'CANCER FUNDRAISER GOLF DAY',
    date: '05/05',
    dealers: [
      501, // Haval
    ],
    img: `${url}/Haval/golfday_event_2023/golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Haval/golfday_event_2023/golfday%20%281%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%282%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%283%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%284%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%285%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%286%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%287%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%288%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%289%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%2810%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%2811%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%2812%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%2813%29.jpg`,
      `${url}/Haval/golfday_event_2023/golfday%20%2814%29.jpg`,
    ],
  },
  {
    blog_id: 12,
    title: 'DORADO SKI GOLF DAY ',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%281%29.jpeg`,
    galleryImages: [
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%281%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%281%29.png`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%282%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%283%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%284%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%285%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%286%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%287%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%288%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%289%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%2810%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%2811%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%2812%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%2813%29.jpeg`,
      `${url}/Haval/ski_golfday_event_2023/ski_golfday%20%2814%29.jpeg`,
    ],
  },
  {
    blog_id: 13,
    title: 'JACKS PAINT GOLF DAY ',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/jacks_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(12).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(13).jpg`,
      `${url}/Haval/jacks_golfday_event_2023/golfday%20(14).jpg`,
    ],
  },
  {
    blog_id: 14,
    title: 'LAERSKOOL RANDFONTEIN GOLF DAY',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/laerskool_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Haval/laerskool_golfday_event_2023/golfday%20(11).jpeg`,
    ],
  },
  {
    blog_id: 15,
    title: 'RANDFONTEIN YOUTH FUN RUN 2023',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/youth_run_event_2023/youth%20run%20(1).jpg`,
    galleryImages: [
      `${url}/Haval/youth_run_event_2023/youth%20run%20(1).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(1).jpg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(1).png`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(2).jpg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(2).png`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(2).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(3).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(3).png`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(4).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(4).png`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(5).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(5).png`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(6).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(7).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(8).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(9).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(10).jpeg`,
      `${url}/Haval/youth_run_event_2023/youth%20run%20(11).jpeg`,
    ],
  },
  {
    blog_id: 16,
    title: 'RANT EN DAL PRISMA GOLF DAY',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/prisma_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(2).JPG`,
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Haval/prisma_golfday_event_2023/golfday%20(6).jpg`,
    ],
  },
  {
    blog_id: 17,
    title: 'SOLIDARITEIT GOLF DAY',
    date: '05/05',
    dealers: [
      501, // Haval
      509, // GWM
    ],
    img: `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(13).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(14).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(15).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(16).jpeg`,
      `${url}/Haval/solidariteit_golfday_event_2023/golfday%20(17).jpeg`,
    ],
  },
  {
    blog_id: 18,
    title: 'CANCER FUNDRAISER GOLF DAY',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Mahindra/golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(12).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(13).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(14).jpg`,
      `${url}/Mahindra/golfday_event_2023/golfday%20(15).jpg`,
    ],
  },
  {
    blog_id: 19,
    title: 'DORADO SKI GOLF DAY ',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/ski_golfday_event_2023/golfday%20%281%29.jpeg`,
    galleryImages: [
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%281%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%281%29.png`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%282%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%283%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%284%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%285%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%286%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%287%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%288%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%289%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%2810%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%2811%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%2812%29.jpeg`,
      `${url}/Mahindra/ski_golfday_event_2023/golfday%20%2813%29.jpeg`,
    ],
  },
  {
    blog_id: 20,
    title: 'JACKS PAINT GOLF DAY',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%281%29.jpg`,
    galleryImages: [
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%281%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%282%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%283%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%284%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%285%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%286%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%287%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%288%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%289%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2810%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2811%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2812%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2813%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2814%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2815%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2816%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2817%29.jpg`,
      `${url}/Mahindra/jacks_golfday_event_2023/golfday%20%2818%29.jpg`,
    ],
  },
  {
    blog_id: 21,
    title: 'LAERSKOOL RANDFONTEIN GOLF DAY',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%281%29.jpeg`,
    galleryImages: [
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%281%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%281%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%282%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%282%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%283%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%283%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%284%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%284%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%285%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%285%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%286%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%286%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%287%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%287%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%288%29.jpeg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%288%29.jpg`,
      `${url}/Mahindra/laerskool_golfday_event_2023/golfday%20%289%29.jpeg`,
    ],
  },
  {
    blog_id: 22,
    title: 'RANDFONTEIN YOUTH FUN RUN 2023',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/youth_run_event_2023/youth_run%20(1).jpeg`,
    galleryImages: [
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(1).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(1).jpg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(1).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(2).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(2).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(3).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(3).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(4).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(4).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(5).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(5).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(6).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(6).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(7).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(7).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(8).jpeg`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(8).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(9).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(10).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(11).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(12).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(13).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(14).png`,
      `${url}/Mahindra/youth_run_event_2023/youth_run%20(15).png`,
    ],
  },
  {
    blog_id: 23,
    title: 'RANT EN DAL PRISMA GOLF DAY',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Mahindra/prisma_golfday_event_2023/golfday%20(9).jpg`,
    ],
  },
  {
    blog_id: 24,
    title: 'SOLIDARITEIT GOLF DAY',
    date: '05/05',
    dealers: [
      500, // Mahindra
    ],
    img: `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Mahindra/solidariteit_golfday_event_2023/golfday%20(13).jpeg`,
    ],
  },
  {
    blog_id: 25,
    title: 'DORADO SKI GOLF DAY ',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/ski_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Ford/ski_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(1).png`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Ford/ski_golfday_event_2023/golfday%20(13).jpeg`,
    ],
  },
  {
    blog_id: 26,
    title: 'JACKS PAINT GOLF DAY ',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/jacks_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(12).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(13).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(14).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(15).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(16).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(17).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(18).jpg`,
      `${url}/Ford/jacks_golfday_event_2023/golfday%20(19).jpg`,
    ],
  },
  {
    blog_id: 27,
    title: 'LAERSKOOL RANDFONTEIN GOLF DAY',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/laerskool_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Ford/laerskool_golfday_event_2023/golfday%20(12).jpg`,
    ],
  },
  {
    blog_id: 28,
    title: 'RANDFONTEIN YOUTH FUN RUN 2023',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/youth_run_event_2023/youth_run%20(1).jpeg`,
    galleryImages: [
      `${url}/Ford/youth_run_event_2023/youth_run%20(1).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(2).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(3).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(4).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(5).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(6).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(7).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(8).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(9).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(10).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(11).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(12).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(13).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(14).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(15).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(16).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(17).jpeg`,
      `${url}/Ford/youth_run_event_2023/youth_run%20(18).jpeg`,
    ],
  },
  {
    blog_id: 29,
    title: 'RANT EN DAL PRISMA GOLF DAY',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/prisma_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(4).JPG`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Ford/prisma_golfday_event_2023/golfday%20(12).jpg`,
    ],
  },
  {
    blog_id: 30,
    title: 'SOLIDARITEIT GOLF DAY',
    date: '05/05',
    dealers: [
      498, // Ford
    ],
    img: `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(13).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(14).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(15).jpeg`,
      `${url}/Ford/solidariteit_golfday_event_2023/golfday%20(16).jpeg`,
    ],
  },
  {
    blog_id: 31,
    title: 'JACKS PAINT GOLF DAY ',
    date: '05/05',
    dealers: [
      499, // Mazda
    ],
    img: `${url}/Mazda/jacks_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(9).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(10).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(11).jpg`,
      `${url}/Mazda/jacks_golfday_event_2023/golfday%20(12).jpg`,
    ],
  },
  {
    blog_id: 32,
    title: 'LAERSKOOL RANDFONTEIN GOLF DAY',
    date: '05/05',
    dealers: [
      499, // Mazda
    ],
    img: `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(5).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(8).jpg`,
      `${url}/Mazda/laerskool_golfday_event_2023/golfday%20(9).jpg`,
    ],
  },
  {
    blog_id: 33,
    title: 'RANDFONTEIN YOUTH FUN RUN 2023',
    date: '05/05',
    dealers: [
      499, // Mazda
    ],
    img: `${url}/Mazda/youth_run_event_2023/youth_run%20(1).jpg`,
    galleryImages: [
      `${url}/Mazda/youth_run_event_2023/youth_run%20(1).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(1).jpg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(2).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(2).jpg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(3).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(3).jpg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(4).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(4).jpg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(5).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(5).jpg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(6).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(7).jpeg`,
      `${url}/Mazda/youth_run_event_2023/youth_run%20(8).jpeg`,
    ],
  },
  {
    blog_id: 34,
    title: 'RANT EN DAL PRISMA GOLF DAY',
    date: '05/05',
    dealers: [
      499, // Mazda
    ],
    img: `${url}/Mazda/prisma_golfday_event_2023/golfday%20(1).jpg`,
    galleryImages: [
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(1).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(2).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(3).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(4).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(5).JPG`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(6).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(7).jpg`,
      `${url}/Mazda/prisma_golfday_event_2023/golfday%20(8).jpg`,
    ],
  },
  {
    blog_id: 35,
    title: 'SOLIDARITEIT GOLF DAY',
    date: '05/05',
    dealers: [
      499, // Mazda
    ],
    img: `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
    galleryImages: [
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(1).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(2).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(3).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(4).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(5).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(6).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(7).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(8).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(9).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(10).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(11).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(12).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(13).jpeg`,
      `${url}/Mazda/solidariteit_golfday_event_2023/golfday%20(14).jpeg`,
    ],
  },
];
