import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { NavLink as useParams } from 'react-router-dom';
import data from './events_data';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    padding: '100px 15px',
    '@media (max-width: 920px)': {
      padding: '0px',
    },
  },
  rootInner: {
    maxWidth: '1770px',
    margin: '0 auto',
  },
  rootInnerTop: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rootInnerTopRight: {
    width: '50%',
    '@media (max-width: 920px)': {
      width: '100%',
    },
  },
  rootInnerTopLeft: {
    width: '50%',
    '@media (max-width: 920px)': {
      width: '100%',
    },
  },
  h1: {
    padding: '1rem 20px',

    fontSize: '30px',
  },
  text: {
    padding: '10px 20px',

    fontSize: '18px',
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '33%',
    marginBottom: '10px',
    height: '3px',
    margin: '0rem 20px',
  },
  blogCardHolder: {
    margin: '20px auto',

    paddingBottom: '20px',
  },
  blogCard: {
    width: 'calc(100% / 4)',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 2px 2px  #EFDF00',
      cursor: 'pointer',
    },
  },
  blogCardBtn: {
    backgroundColor: 'unset',
    width: 'calc(100% / 3)',
    padding: '10px',
    margin: '0px 15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  blogCardTextHolder: {
    padding: '0px 15px',
    fontWeight: 'bolder',
  },
  blogCardImg: {
    borderRadius: '5px',
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    '@media (max-width: 920px)': {
      borderRadius: '0px',
    },
  },
  textHeading: {
    padding: '0px 20px',
    fontWeight: 'bold',

    fontSize: '20px',
  },
  imgHolderOutter: {
    margin: '0px -10px',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 920px)': {
      margin: '0px 0px',
    },
  },
  imgHolder: {
    width: 'calc(100% / 4 - 20px)',
    margin: '10px',
    '@media (max-width: 920px)': {
      width: 'calc(100% / 2 - 20px)',
    },
  },
  img: {
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  lightbox: {
    position: 'fixed',
    top: '0',
    background: '#0000009e',
    width: '100%',
    left: '0',
    bottom: '0px',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    zIndex: '99',
    alignItems: 'center',
  },
  lightboxImg: {
    padding: '40px',
    aspectRatio: '10/7',
    maxHeight: '80%',
    borderRadius: '5px',
    objectFit: 'contain',
  },
  lightboxClose: {
    color: 'red',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10px',
    fontSize: '25px',
    right: '10px',
    cursor: 'pointer',
  },
  lightboxVideo: {
    width: '50%',
  },
  video: {
    borderRadius: '5px',
  },
}));

function EventDetailed() {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [blogFound, setBlogFound] = useState();
  const [lightbox, setLightbox] = useState(false);
  const [lightboxImg, setLightboxImg] = useState('');
  const params = useParams();

  useEffect(() => {
    setBlogFound(data.filter((b) => b.blog_id === params.event_id)[0]);
  }, [data]);

  const handleLightBox = (img) => {
    if (!lightbox) {
      setLightbox(!lightbox);
    } else {
      setLightboxImg(img);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        {lightbox && (
          <div className={classes.lightbox}>
            <div
              onClick={() => {
                setLightbox(false);
              }}
              className={classes.lightboxClose}
            >
              X
            </div>
            {lightboxImg.split('.')[lightboxImg.split('.').length - 1] === 'mov' ? (
              <div className={classes.lightboxVideo}>
                <video width="100%" height="100%" controls className={classes.lightboxImg}>
                  <source src={lightboxImg} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img alt="" src={lightboxImg} className={classes.lightboxImg} />
            )}
          </div>
        )}
        <div className={classes.rootInnerTop}>
          <div className={classes.h1}>{blogFound?.title}</div>
          <Divider className={classes.dividerTitle} />
          <div className={classes.imgHolderOutter}>
            {blogFound?.galleryImages?.map((img) => {
              const fileExtension = img.split('.')[img.split('.').length - 1].toLowerCase();

              if (fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg') {
                return (
                  <div
                    className={classes.imgHolder}
                    onClick={() => {
                      handleLightBox(img);
                    }}
                  >
                    <img alt="" className={classes.img} src={img} width="100%" />
                  </div>
                );
              }
              return (
                <div
                  className={classes.imgHolder}
                  onClick={() => {
                    handleLightBox(img);
                  }}
                >
                  <video width="100%" height="240" controls className={classes.video}>
                    <source src={img} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetailed;
