import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, Button, Typography } from '@material-ui/core';
import Carousel from '../../components/shared/Carousel/SharedCarousel';
import { BASE_URI, JAC_DEALER_ID } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import CardSpecialDealsImg from '../../components/shared/assets/jac/jac_special_deals_card.png';
import CardAftersalesImg from '../../components/shared/assets/jac/chery_aftersales_card.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  sectionBackground: {
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    width: '100%',
  },
  sectionTitleContainer: {
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    marginLeft: '50px',
    textTransform: 'uppercase',
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1,
    zIndex: 2,
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    borderBottom: ({ websiteColors }) => `5px solid ${websiteColors.primaryColor}`,
    '@media (max-width: 1000px)': {
      fontSize: '2.5rem',
    },
    '@media (max-width: 500px)': {
      fontSize: '2rem',
    },
  },
  sectionTitleBackgroundLight: {
    paddingLeft: '50px',
    textTransform: 'uppercase',
    fontSize: '7rem',
    fontWeight: 600,
    zIndex: 1,
    color: '#f3f3f3',
    position: 'absolute',
    '@media (max-width: 959px)': {
      fontSize: '3rem',
    },
  },
  actionCardsContainer: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  actionCardContainerLeft: {
    minWidth: '300px',
    width: '30%',
    aspectRatio: '1/1',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px 50px 0 50px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: '0.2s all ease-in',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
    },
  },
  actionCardContainerRight: {
    minWidth: '300px',
    width: '30%',
    aspectRatio: '1/1',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px 50px 50px 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: '0.2s all ease-in',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
    },
  },
  actionCardTitle: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  actionCardText: {
    padding: '5px',
    color: 'white',
    fontSize: '1.3rem',
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  actionCardButton: {
    padding: '5px 30px',
    color: 'white',
    background: 'transparent',
    fontSize: '1.1rem',
    fontWeight: '400',
    textTransform: 'capitalize',
    border: '1px solid white',
    borderRadius: '20px',
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullDivider: {
    width: '100%',
    minHeight: '10px',
    transform: 'translate(0, -10px)',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
  },
}));

const HomeComonentJAC = () => {
  let cancel;

  const { globalDealer, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [slides, setSlides] = useState();

  useEffect(() => {
    const params = {
      dealerId: globalDealer?.dealerId || JAC_DEALER_ID,
      pageNumber: 1,
      pageSize: 25,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
      }));
      setSlides(tempSlides.reverse());
    });

    return () => cancel();
  }, []);

  return (
    <div className={classes.root}>
      {slides && <Carousel slides={slides} />}

      <div className={classes.dividerContainer}>
        <div className={classes.fullDivider} />
      </div>

      <section id="models" />
      <section id="road-assist-banner" />
      <section id="actions" className={classes.actionCardsContainer}>
        <Link
          to="/special-deals"
          className={classes.actionCardContainerLeft}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${CardSpecialDealsImg})`,
          }}
        >
          <Typography className={classes.actionCardTitle}>Special deals</Typography>
          <Typography className={classes.actionCardText}>Browse our deals</Typography>
          <Button className={classes.actionCardButton}>View Deals</Button>
        </Link>

        <Link
          to="/aftersales"
          className={classes.actionCardContainerRight}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${CardAftersalesImg})`,
          }}
        >
          <Typography className={classes.actionCardTitle}>JAC aftersales</Typography>
          <Typography className={classes.actionCardText}>Select your service</Typography>
          <Button className={classes.actionCardButton}>Select service</Button>
        </Link>
      </section>
    </div>
  );
};

export default HomeComonentJAC;
