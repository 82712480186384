import React, { useContext } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer';
import HomeComponent from '../Home/Home';
import AboutUs from '../About/About';
import Showroom from '../Showroom/Showroom';
import DetailedVehicle from '../DetailedVehicle/DetailedVehicle';
import UsedVehicles from '../../components/UsedVehicles/UsedVehicles';
import DetailedUsedVehicle from '../../components/DetailedUsedVehicle/DetailedUsedVehicle';
import SpecialDeals from '../../components/SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from '../../components/DetailedSpecialDeals/DetailedSpecialDeals';
import Aftersales from '../../components/AfterSales/AfterSales';
import Service from '../BookService/BookService';
import Parts from '../../components/Parts/Parts';
import VehicleFinance from '../../components/Finance/VehicleFinance';
import TestDrive from '../../components/BookATestDrive/BookATestDrive';
import ContactUs from '../../components/ContactUs/ContactUs';
import Cookies from '../../components/Legal/Cookies';
import Popi from '../../components/Legal/Popia';
// import Staff from '../../components/Staff/Staff';
import TermsAndConditions from '../../components/Legal/Terms';
import ComingSoonVehicle from '../ComingSoonVehicle/ComingSoonVehicle';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

export default function DashboardContainerJAC() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();
  return (
    <main>
      <TopBar />
      <div style={{ minHeight: '50vh', overflowY: 'scroll' }}>
        <Route exact path="/" component={HomeComponent} />
        <Route exact path="/about-us" render={() => <AboutUs addTopMargin />} />
        <Route exact path="/show-room" render={() => <Showroom addTopMargin />} />
        <Route path="/coming-soon/:model/:stockId/" component={ComingSoonVehicle} />
        <Route path="/show-room/:model/:stockId/" component={DetailedVehicle} />
        <Route exact path="/pre-owned" render={() => <UsedVehicles addTopMargin />} />
        <Route path="/pre-owned/:stockId" render={() => <DetailedUsedVehicle addTopMargin />} />
        <Route exact path="/special-deals" render={() => <SpecialDeals addTopMargin />} />
        <Route path="/special-deals/:dealId" render={() => <DetailedSpecialDeals addTopMargin />} />
        <Route exact path="/aftersales" render={() => <Aftersales addTopMargin />} />
        <Route exact path="/parts" render={() => <Parts addTopMargin />} />
        <Route exact path="/service" render={() => <Service addTopMargin />} />
        <Route exact path="/apply-for-finance" render={() => <VehicleFinance addTopMargin />} />
        <Route exact path="/book-a-testdrive" component={TestDrive} />
        <Route exact path="/book-a-testdrive/:stockId" component={TestDrive} />
        <Route exact path="/contact-us" render={() => <ContactUs addTopMargin />} />
        <Route exact path="/cookies" render={() => <Cookies addTopMargin />} />
        <Route exact path="/privacy" render={() => <Popi addTopMargin />} />
        {/* <Route exact path="/Staff" render={() => <Staff addTopMargin />} /> */}
        <Route exact path="/terms" render={() => <TermsAndConditions addTopMargin />} />
      </div>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} />
    </main>
  );
}
