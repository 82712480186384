import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 15px 75px 15px',
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
  inner: {
    maxWidth: '1170px',
  },
  Intro: {
    fontSize: '28px',
    fontWeight: 'bold',

    margin: '20px 0px',
  },
  HeadingIntro: {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px 0px',
  },
  textIntro: {
    fontSize: '14px',
    padding: '10px 0px',
    marginBottom: '0px',
    display: 'block',
  },
  section: {
    width: '50%',
    paddingRight: '15px',
  },
  sectionHolder: {
    display: 'flex',
  },
  listFlex: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 40px',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      padding: '20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      flexDirection: 'column',
      padding: '20px',
    },
  },
  list: {
    width: '24%',
    textAlign: 'left',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  img: {
    height: '100%',
    maxHeight: '160px',
    transition: '0.5s ease all',
    margin: '20px 0px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  imgHolder: {
    overflow: 'hidden',
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '25px',
  },
}));

const AboutUs = () => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Typography variant="h1" className={classes.titleTopAccent}>
          About Us
        </Typography>
        <Typography variant="h2" className={classes.HeadingIntro}>
          MEKOR Group History
        </Typography>
        <div className={classes.sectionHolder}>
          <div className={classes.section}>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Motors Group was established in 2000, initially exclusively as a Honda retail
              business, when HONDA Motor Corporation Japan established itself in Southern Africa.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              Today MEKOR Group operates out of 12 locations and it represents some very reputable
              brands, known for quality, reliability and safety.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Group represents the HONDA brand in the Western Cape through 5 very conveniently
              located outlets, in Cape Town CBD, Century City (at Canal Walk Shopping Centre), which
              is open 7 days per week, in Durban Road Tygervalley, in Old Stanhope Road Claremont &
              in Constantiaberg - Main Road Diepriver.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              HONDA Motorcycles are represented at both HONDA Cape Town CBD and at the HONDA
              Adventure Centre at HONDA Tygerberg.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Mazda was established in 2014, when Mazda Southern Africa became a wholly owned
              subsidiary of MAZDA Motor Corporation Japan. MEKOR Mazda is conveniently located in
              Durban Road Tygervalley & at Old Stanhope Road, Claremont.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Suzuki was established in 2014 and it proudly represents the SUZUKI brand in 5
              convenient locations. Suzuki Tygervalley is in High Street, Rosenpark, just off Durban
              Road, Suzuki Kenilworth is next to Kenilworth Centre, Suzuki Constantiaberg is in Main
              Road, Diepriver and the most recent addition, Suzuki Cape Gate has a retail boutique
              inside Cape Gate Shopping Centre and a fully integrated dealership on the northern
              side of Cape Gate Shopping Centre. Suzuki Cape Gate is open 7 days per week.
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR BAIC and MEKOR JAC were established in 2023 and is based at Canal Walk Shopping
              Centre (open 7 days per week).
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR OMODA, incorporating JAECOO, opened its doors in spring of 2023 at 5 High Street
              Rosenpark, just off Durban Road.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The latest addition is MEKOR BYD, which is based at 73 Hertzog Boulevard, Foreshore,
              Cape Town.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Group actively purchase quality used vehicles through http://selltomekor.co.za
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              Premium Pre-Owned vehicles are retailed at Auto Atlantic Cars in Canal Walk (Pod 2 on
              the parking deck), which is open 7 days per week, as well as at Auto Atlantic Cars at
              MEKOR Constantiaberg, 155 Main Road, Diepriver.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Group is a reputable player in the retail motor industry, as an employer and as
              a long term trusted franchised dealer group in its dealings with suppliers, fleet
              owners, retail banks and the valued clients enjoying the special experience of taking
              delivery or servicing of a car or motorcycle.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The Group is privately managed and owned. For any further information, kindly enquire
              with Debbie Hovey debbieh@mekor.co.za
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
