import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs } from '@red-build/leadcentre';
import axios from 'axios';
import { BASE_URI } from '../../components/shared/Constants';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerHolder: {
    padding: '15px',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 30px',
    flexDirection: 'column',
  },
  cardContent50: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 20px',
    flexDirection: 'column',
  },
  title: {
    borderLeft: ({ websiteColors }) => `3px solid${websiteColors.accentColor}`,
    padding: '5px 30px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  CardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
    },
  },
  imgCard: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    backgroundColor: 'white',
    textAlign: 'center',
    '@media (max-width: 959px)': {
      width: '100%',
    },
  },
  contactCard: {
    backgroundColor: 'white',
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    '@media (max-width: 959px)': {
      width: '100%',
    },
  },
  tc: {
    padding: '10px',
    paddingTop: '20px',
  },
}));

const ComingSoonVehicle = () => {
  const params = useParams();
  const [vehicle, setVehicle] = useState({});
  const { websiteColors, globalDealer } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${params?.stockId}`,
    })
      .then((response) => {
        setVehicle(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [params]);

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.CardHolderWrap}>
          <div className={classes.imgCard}>
            <img
              alt={vehicle?.introductionTitle}
              src={vehicle?.contentImages ? vehicle.contentImages[0]?.image?.imageUrl : null}
            />
          </div>
          <div className={classes.contactCard}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>
            <div className={classes.cardContent50}>
              <h1>{vehicle?.introductionTitle}</h1>
              <Typography variant="body1" className={classes.tc}>
                {vehicle?.introductionDesc}
              </Typography>
              <ContactUs
                dealerId={globalDealer?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={1}
                dealerName={globalDealer?.name}
                stockId={params?.stockId}
              />
              <Typography variant="body1" className={classes.tc}>
                Mekor Motors will not disclose your information to anyone else without consent. For
                further information see our privacy policy.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonVehicle;
