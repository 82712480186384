import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DealsFilter from './DealsFilter';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    padding: '75px 0px',
  },
  background: {
    padding: '0px 15px',
  },
  title: {
    textAlign: 'center',
    fontSize: '44px',
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    width: '100%',
    margin: '10px',
    maxWidth: 'calc(100% / 2 - 20px)',
    cursor: 'pointer',
    '@media (max-width: 425px)': {
      maxWidth: 'calc(100% / 1 - 20px)',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
  },
  textContainer: {
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '30px',
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default function SpecialDeals() {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [sortDirection, setSortDirection] = useState('Desc');
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const { websiteColors } = useContext(DealerContext);
  const [sortDeals, setSortDeals] = useState([]);
  const { deals } = DealsFilter(pageNumber, sortDirection);
  const handlePageChange = (deal) => {
    history.push(`/special-deals/${deal?.offerId}`, deal);
  };

  useEffect(() => {
    setSortDeals(deals.sort((a, b) => a.priority - b.priority));
  }, [deals]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h1">
        Our Special Deals
      </Typography>
      <div className={classes.background}>
        <div className={classes.cardHolder}>
          {sortDeals.length > 0 ? (
            sortDeals.map((deal, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={classes.card}>
                <img
                  alt=""
                  onClick={() => {
                    handlePageChange(deal);
                  }}
                  src={deal?.imageUrl}
                  width="100%"
                />
              </div>
            ))
          ) : (
            <div className={classes.textContainer}>
              <Alert
                style={{ backgroundColor: websiteColors?.accentColor || 'blue' }}
                severity="info"
              >
                There are currently no available offers.
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
