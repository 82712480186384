/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';
import { BASE_URI } from '../shared/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'flex-start',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  overlaySection: {
    alignSelf: 'end',
    width: '40%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    alignItems: 'end',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      width: '100%',
      padding: '10px 10px',
    },
    zIndex: 2,
  },
  heading: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginLeft: 20,
    marginTop: 20,
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ accentColor }) => `${accentColor}`,
    fontWeight: 'bold',
  },
  dealerSection: {
    marginRight: 10,
    padding: '40px 40px',
    right: 0,
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      overflowY: 'scroll',
      '&:nth-child(1)': {
        margin: '0px',
      },
    },
    overflowY: 'scroll',
    height: '100vh',
    // height: '90vh',
  },
  singleDealer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    marginBottom: 20,
    boxShadow: '0px 0px 30px 5px black',
  },
  dealerHeadingSection: {
    display: 'flex',
    flex: 1,
    padding: 20,
    '@media (max-width: 959px)': {
      padding: 5,
      justifyContent: 'center',
      alignSelf: 'center',
    },
  },
  dealerButton1: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#d6ebf9',
    padding: theme.spacing(0.5, 0, 0.5),
    fontWeight: 'bold',
    color: 'white!important',
    cursor: 'pointer',
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
  dealerButton1Text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    color: 'black',
    justifyContent: 'center',
    '&:hover': {
      color: 'black',
    },
  },
  dealerButton2: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: ({ secondaryColor }) => `${secondaryColor}`,
    padding: theme.spacing(0.5, 0, 0.5),
    color: 'white!important',
    cursor: 'pointer',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
  dealerButton2Text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'center',
    '&:hover': {
      color: 'black',
    },
  },
  fontSmall: {
    fontSize: '0.7rem',
  },
  fontSmallHeading: {
    display: 'flex',
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 10,
  },
  dealerTextHolder: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    padding: 20,
  },
  numberHolder: {
    display: 'flex',
    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  mapholder: {
    top: 75,
    position: 'absolute',
    display: 'flex',
    height: 'calc(100% - 350px)',
    '@media (max-width: 768px)': {
      top: 0,
      height: 'calc(100% - 740px)',
    },
    flex: 1,
    flexDirection: 'column',
    zIndex: 0,
  },
  mapBannerHolder: {
    width: '100%',
  },
  dealerLogoSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    maxHeight: 80,
    paddingTop: 20,
    paddingRight: 20,
  },
  dealerInfoSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
    },
  },
  dealerButtonSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
    },
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
}));

// const cleanName = (dealerName) => dealerName.replace(/ /g, '-').toLowerCase();

const Dealers = () => {
  const { dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ ...websiteColors });
  const [selectedDealer, setSelectedDealer] = useState({});
  const [expandedDealerList, setExpandedDealerList] = useState([]);
  const [filterExpandedDealerList, setFilterExpandedDealerList] = useState([]);
  const aSeperateDealers = ['Mekor Honda Motorcycles Master', 'JAC Century City'];

  useEffect(async () => {
    if (dealerList?.length > 0) {
      const getDealerWebsites = async (dealerID) => {
        const result = await axios({
          method: 'GET',
          url: `${BASE_URI}/DealerWebsites/${dealerID}`,
        })
          .then((res) => res.data.links)
          .catch(() => null);
        return result;
      };

      const l = await dealerList.reduce(async (ac, v) => {
        const acc = await ac;
        const links = await getDealerWebsites(v.dealerId);
        if (links?.length > 0) {
          const mapURL = links.find((lVal) => lVal.name === 'mapsURL')?.link;
          const mapEmbed = links.find((lVal) => lVal.name === 'mapsEmbed')?.link;

          return [...acc, { ...v, mapURL, mapEmbed }];
        }
        return [...acc, v];
      }, []);

      setExpandedDealerList(l);
    }
  }, [dealerList]);

  useEffect(() => {
    setFilterExpandedDealerList(expandedDealerList.filter((s) => s?.name !== 'Auto Atlantic Cars'));
  }, [expandedDealerList]);

  const renderSeperateDealer = (dealer) => {
    if (aSeperateDealers.includes(dealer?.name)) {
      return {
        logo: dealer?.websiteColors?.logo,
      };
    }
    return {
      logo: dealer?.relatedBrands[0].logo,
    };
  };

  return (
    <div className={classes.root}>
      <iframe
        className={classes.mapholder}
        title="mapFrame"
        src={
          selectedDealer?.mapEmbed
            ? selectedDealer.mapEmbed
            : `https://maps.google.com/maps?q=${selectedDealer.addressLongitude},${selectedDealer.addressLatitude}&via=0&z=16&output=embed`
        }
        height="100%"
        width="100%"
        // this might be deprecated, but it still makes a difference.
        frameBorder="0"
      />
      <div className={classes.overlaySection}>
        <div className={classes.dealerSection}>
          {filterExpandedDealerList
            ?.sort((a, b) => {
              if (a.name === 'Auto Atlantic Cars' || b.name === 'Auto Atlantic Cars') {
                return a.name === 'Auto Atlantic Cars' ? -2 : 2;
              }

              return a.relatedBrands[0].name > b.relatedBrands[0].name ? 1 : -1;
            })
            .map((v) => {
              switch (v?.dealerId) {
                case 554:
                case '554':
                  v.websiteUrl = 'https://www.hondacanalwalk.co.za/';
                  break;
                case 557:
                case '557':
                  v.websiteUrl = 'https://www.hondatygerberg.co.za/';
                  break;
                case 556:
                case '556':
                  v.websiteUrl = 'https://www.hondaclaremont.co.za/';
                  break;
                case 555:
                case '555':
                  v.websiteUrl = 'https://www.hondacapetown.co.za/';
                  break;
                default:
              }

              return v;
            })
            .map((dealer, index) => {
              const dealerData = renderSeperateDealer(dealer);
              if (dealer.name.includes('Master') && !dealer.name.includes('Motorcycles Master')) {
                return null;
              }
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classes.singleDealer}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'row' }}
                    onClick={() => setSelectedDealer(dealer)}
                  >
                    <div className={classes.dealerHeadingSection}>
                      <Typography variant="h6">{dealer.name.replace('Master', '')}</Typography>
                    </div>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <div key={index} className={classes.dealerLogoSection}>
                      <img
                        style={{ objectFit: 'contain' }}
                        alt=""
                        src={dealerData.logo}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div
                    className={classes.dealerInfoSection}
                    onClick={() => setSelectedDealer(dealer)}
                  >
                    <div className={classes.dealerTextHolder}>
                      <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                        Address
                      </div>
                      <div className={classes.fontSmall}>
                        <a
                          href={
                            dealer?.mapURL
                              ? dealer?.mapURL
                              : `https://www.google.com/maps/@${dealer?.addressLongitude},${dealer?.addressLatitude},16z?entry=ttu`
                          }
                          target="_blank"
                          style={{ fontSize: '15px' }}
                          rel="noreferrer"
                        >
                          {dealer?.address?.streetAddress} , {dealer?.address?.streetAddressPostal}
                        </a>
                      </div>
                    </div>
                    <div className={classes.dealerTextHolder}>
                      <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                        Business Hours
                      </div>
                      <div className={classes.fontSmall}>Monday - Friday: 8:00 - 17:00</div>
                      <div className={classes.fontSmall}>Saturday: 8:00 - 13:00</div>
                      <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                        Contact No
                      </div>
                      <div className={classes.fontSmall}>
                        <a
                          href={`tel:${dealer?.newCarsPhoneNumber}`}
                          target="_blank"
                          style={{ fontSize: '15px' }}
                          rel="noreferrer"
                        >
                          {dealer?.newCarsPhoneNumber}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={classes.dealerButtonSection}>
                    {/* <div className={classes.dealerButton1}>
      <div
        className={classes.dealerButton1Text}
        onClick={() => {
          window.location.href = `/dealer/${cleanName(dealer.name)}/used-vehicles`;
        }}
      >
        View Used Vehicles
      </div>
    </div> */}
                    <div className={classes.dealerButton2}>
                      <div
                        className={classes.dealerButton2Text}
                        onClick={() => {
                          // normalize to not have schema.
                          const hostOnly = dealer?.websiteUrl.replace(/^(http)(s)?:\/\//, '');
                          // force https schema.
                          window.location.href = `https://${hostOnly}`;
                        }}
                      >
                        {dealer.name} Website <LaunchOutlinedIcon fontSize="small" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Dealers;
