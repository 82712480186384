import React, { useContext } from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { Parts as PartsForm } from '@red-build/leadcentre';
import DownloadIcon from '@mui/icons-material/Download';
import { DEALERID, BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 15px',
    backgroundColor: 'white',
  },
  innerSection: {
    width: '100%',
    maxWidth: '1260px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
}));

const Parts = () => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const handleDownload = () => {
    // Replace 'filename.pdf' with the actual name of your PDF file
    const pdfUrl = `${process.env.PUBLIC_URL}/OmodaMerchandiseBrochure2024.pdf`;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'OmodaMerchandiseBrochure2024.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography
            variant="h1"
            className={classes.titleTop}
            style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
          >
            NEED
          </Typography>
          <Typography
            variant="h1"
            className={classes.titleTopAccent}
            style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
          >
            PARTS?
          </Typography>

          <Typography
            variant="body2"
            className={classes.text}
            style={{ fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}` }}
          >
            If you require support with obtaining parts for your vehicle, kindly provide your
            information, and one of our parts specialists will be in touch with you promptly.
          </Typography>
          <Button
            style={{ marginTop: '50px' }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            size="large"
            onClick={handleDownload}
            startIcon={<DownloadIcon />}
          >
            Merchandise
          </Button>
        </div>
        <div className={classes.section}>
          <PartsForm
            variant="standard"
            masterDealerId={DEALERID}
            dealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name?.replace('Master', ' ')}
            activeDealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            dealerList={dealerList}
          />
        </div>
      </div>
    </div>
  );
};

export default Parts;
