/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { MetaTagsProvider } from '@red-build/leadcentre';
import DashboardContainer from './components/MainContainer';
import DashboardContainerBaic from './BaicComponents/Dashboard/DashboardContainer';
import DashboardContainerJAC from './JACComponents/Dashboard/DashboardContainer';
import DashboardContainerOmoda from './OmodaComponents/MainContainer';
import mekor from './components/shared/assets/favicon.ico';
import baicFavi from './components/shared/assets/baicfavi.png';
import jacFavi from './components/shared/assets/jac/logo_jac.png';
import omoda from './components/shared/assets/OmodaAssets/favicon.ico';
import { DealerContext } from './components/shared/contexts/DealerContext';
import { MakeProvider } from './components/shared/contexts/MakeContext';
import { CompareProvider } from './components/shared/contexts/CompareContext';
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import { ReactComponent as Loader } from './components/shared/assets/loading.svg';

function App() {
  localStorage.clear();
  const { globalDealer } = useContext(DealerContext);
  const favicon = document.getElementById('favi');

  // eslint-disable-next-line consistent-return
  const renderDealer = () => {
    const dealer = globalDealer?.dealerId;
    switch (dealer) {
      case 551: // BAIC DEALER ID
        document.title = 'Baic century city';
        favicon.setAttribute('href', baicFavi);
        return <DashboardContainerBaic />;
      case 553: // JAC DEALER ID
        document.title = 'Mekor JAC';
        favicon.setAttribute('href', jacFavi);
        return <DashboardContainerJAC />;
      case 550: // OMODA DEALER ID
        document.title = 'Mekor Omoda';
        favicon.setAttribute('href', omoda);
        return <DashboardContainerOmoda />;
      case 552: // Mekor DEALER ID
        document.title = 'Mekor Group';
        favicon.setAttribute('href', mekor);
        return <DashboardContainer />;
      default:
        return (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        );
    }
  };

  return (
    <Router>
      <FavouriteProvider>
        <CompareProvider>
          <ThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <CssBaseline />
              <VehicleProvider>
                <MakeProvider>
                  <MetaTagsProvider>{globalDealer && renderDealer()}</MetaTagsProvider>
                </MakeProvider>
              </VehicleProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CompareProvider>
      </FavouriteProvider>
    </Router>
  );
}

export default App;
